import React from "react";
import { useTranslation } from 'react-i18next';

import ModalLayout from "../../components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import CalendarIco from "../../../public/images/calendar-ico.svg";
import ClockIco from "../../../public/images/clock-ico.svg";
import DollarIco from "../../../public/images/dollar-ico.svg";
import i18n from "app/i18n";

export default function DetailsSession({
  isOpen,
  onClose,
  session,
  onEditSession,
  onCancelSession,
}: any) {
  const { t } = useTranslation();
  const cancelButton = (
    <button
      data-testid="cancel-edit"
      className={`${styles.button} ${styles.cancel_button}`}
      onClick={onCancelSession}
    >
      {t("Cancel Session")}
    </button>
  );

  const editButton = (
    <button
      className={`${styles.button} ${styles.confirm_button}`}
      type="submit"
      onClick={() => onEditSession(session)}
    >
      {t("Edit Session")}
    </button>
  );
  const startDate = new Date(session.start);
  const formattedDate = startDate.toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' });
  const coachName = session.coachProfile.firstName + ' ' + session.coachProfile.lastName;

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.modal_header}>
        <div className={styles.title}>{t("Details")}</div>
        <i className={`ico ico-x ${styles.modal_status_icon}`} onClick={onClose}/>
      </div>
      <div className={styles.details}>
        <div className={styles.rows}>
          <div className={styles.circle}>
            <img src={CalendarIco} alt="date" className={styles.icon}/>
          </div>
          <div className={styles.value}>{formattedDate}</div>
        </div>
        <div className={styles.rows}>
          <div className={styles.circle}>
            <img src={ClockIco} alt="time" className={styles.icon}/>
          </div>
          <div className={styles.value}>{session.intervalTimeString}</div>
        </div>
        <div className={styles.rows}>
          <div className={styles.circle}>
            <img src={DollarIco} alt="price" className={styles.icon}/>
          </div>
          <div className={styles.value}>{session?.coachProfile?.currencySymbol || '$'}{session.rate}</div>
        </div>
      </div>
      <div>
        <div className={styles.subtitle}>Coach</div>
        <div className={styles.coach_info}>
          <img src={session.coachProfile.profilePicture} alt="coach" className={styles.coach_img}/>
          <div className={styles.coach_name}>{coachName}</div>
        </div>
      </div>
      {session.lessonRateTitle && (
        <div>
          <div className={styles.subtitle}>Session</div>
          <div className={styles.note}>{session.lessonRateTitle}</div>
        </div>
      )}
      {session.notes && (
        <div>
          <div className={styles.subtitle}>Notes</div>
          <div className={styles.note}>{session.notes}</div>
        </div>
      )}
      <div className={styles.footer}>
        <div className={styles.buttons_container}>
          {cancelButton} {editButton}
        </div>
      </div>
    </ModalLayout>
  );
}
