import React, { memo } from "react";
import styles from "./styles.module.scss";
import ActivityLayout from "app/components/Layouts/ActivityPageLayout";
import TabButton from "app/components/Button/TabButton";
import { ActivityInvitationsModel } from "./indexModel";
import IncomingInvitations from "./IncomingInvitations";
import OutgoingInvitations from "./OutgoingInvitations";
import { useTranslation } from "react-i18next";

const ActivityInvitations = () => {
  const { t } = useTranslation();
  const { activeTab, handleTabChange, outgoingInvitations, invitations } =
    ActivityInvitationsModel();

  return (
    <div className="invitations-container">
      <ActivityLayout>
        <div className={styles.panel}>
          <div className={styles.panel_title_contaniner}>
            <h2>{t("Invitations")}</h2>
          </div>
          <div className={styles.tabs_container}>
            <TabButton
              className={styles.tab_btn}
              label={`${t("Incoming")} (${invitations ? invitations : 0})`}
              onClick={() => handleTabChange("incoming")}
              isActive={activeTab === "incoming"}
            />
            <TabButton
              className={styles.tab_btn}
              label={`${t("Outgoing")} (${
                outgoingInvitations ? outgoingInvitations : 0
              })`}
              onClick={() => handleTabChange("outgoing")}
              isActive={activeTab === "outgoing"}
            />
          </div>
        </div>
        {activeTab === "incoming" && <IncomingInvitations />}
        {activeTab === "outgoing" && (
          <OutgoingInvitations outgoingInvitationsCount={outgoingInvitations} />
        )}
      </ActivityLayout>
    </div>
  );
};

export default memo(ActivityInvitations);
