import { useEffect, useState, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useActions } from "app/utils/hooks";
import {
  fetchSubscriptions,
  clearSubscription,
} from "app/actions/subscription";
import { RootState } from "typedefs";
import SubscriptionModel from "app/models/SubscriptionModel";
import moment from "moment";

export const useSubscriptionModel = () => {
  const { data: subscriptions, pending } = useSelector(
    (state: RootState) => state.subscription,
    shallowEqual,
  );

  const [cancelModal, setCancelModal] = useState<boolean>(false);

  const subscriptionActions = useActions({
    fetchSubscriptions,
    clearSubscription,
  });

  const toggleCancelModal = useCallback(() => {
    setCancelModal((prev) => !prev);
  }, []);

  useEffect(() => {
    subscriptionActions.fetchSubscriptions();

    return () => {
      subscriptionActions.clearSubscription();
    };
  }, [subscriptionActions]);

  const isExpired = useCallback((subscription: SubscriptionModel) => {
    return (
      subscription.expiresAt &&
      subscription.expiresAt < new Date().toISOString()
    );
  }, []);

  const canCancel = useCallback(
    (subscription: SubscriptionModel) => {
      return subscription.term !== "Trial" && !isExpired(subscription);
    },
    [isExpired],
  );

  const canReactivate = useCallback(
    (subscription: SubscriptionModel) => {
      return subscription.term === "Trial" && isExpired(subscription);
    },
    [isExpired],
  );

  const isTrial = useCallback((subscription: SubscriptionModel) => {
    return subscription.term === "Trial";
  }, []);

  const formatDate = useCallback((date: string) => {
    return moment(date).format("MMM DD, YYYY");
  }, []);

  return {
    subscriptions,
    pending,
    cancelModal,
    toggleCancelModal,
    isExpired,
    canCancel,
    canReactivate,
    isTrial,
    formatDate,
  };
};
