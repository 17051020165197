import { DateTime, DateTimeFormatOptions } from "luxon";

export const DATETIME_FULL_WEEKDAY: DateTimeFormatOptions  = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
};

export const DATE_FULL_WEEKDAY: DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
};


export const weekFormat = (date: DateTime, calendarView: string) => {
  let startOfWeek = date;
  let endOfWeek: DateTime = date.plus({ day: 6 });

  if (calendarView === "timeGridNarrow") {
    if (startOfWeek.weekday !== 7) {
      startOfWeek = startOfWeek.minus({ days: startOfWeek.weekday });
    }

    endOfWeek = startOfWeek.plus({ days: 6 });
  }

  const startMonth = startOfWeek.toLocaleString({ month: "long" });
  const startYear = startOfWeek.toLocaleString({ year: "numeric" });

  let dateLabel: string;

  if (startOfWeek.month === endOfWeek.month) {
    dateLabel = `${startMonth} ${startYear}`;
  } else {
    if (startOfWeek.year === endOfWeek.year) {
      dateLabel = `${startOfWeek.toLocaleString({ month: "short" })} - ${endOfWeek.toLocaleString({ month: "short", year: 'numeric' })}`;
    } else {
      dateLabel = `${startOfWeek.toLocaleString({ month: "short", year: 'numeric' })} - ${endOfWeek.toLocaleString({ month: "short", year: 'numeric' })}`;
    }
  }

  return dateLabel;
};
