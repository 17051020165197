import React, { useContext } from "react";
import ManageCoachPrograms from "./ManageCoachPrograms";
import StudentPrograms from "./ManageStudentPrograms";
import { RoleType, UserContext } from "app/contexts/UserContext";

const ManagePrograms = () => {
  const user = useContext(UserContext);
  const canCreate = user.role === RoleType.COACH || user.role === RoleType.ADMIN;
  return canCreate ? <ManageCoachPrograms /> : <StudentPrograms />;
};

export default ManagePrograms;
