import React from "react";
import styles from "./styles.module.scss";
import { v4 } from "uuid";

interface Props {
  mediaData: any;
  onClick: () => void;
  shouldShowMore?: boolean;
  showMoreOverlay?: React.ReactElement;
}
const ImagePostItem = ({
  mediaData,
  onClick,
  shouldShowMore,
  showMoreOverlay,
}: Props) => {
  return (
    <div
      key={v4()}
      className={styles.image_item_container}
      onClick={!shouldShowMore ? onClick : undefined}
    >
      {shouldShowMore && showMoreOverlay}
      <img src={mediaData?.originalUrl} alt="media" />
    </div>
  );
};

export default ImagePostItem;
