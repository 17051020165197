import React from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import DropdownButton from "app/components/DropdownButton";
import TabButton from "app/components/Button/TabButton";
import { cls } from "app/utils";
import SpaceSettings from "app/components/SpaceTabsContainer/SpaceSettings";

interface SpaceHeaderProps {
  displaySettings?: boolean;
  title: string;
  avatar?: string;
  sport?: string;
  onTimelineClick?: () => void;
  onScheduleClick?: () => void;
  onMembersClick?: () => void;
}

const SpaceHeader = ({
  displaySettings = true,
  title,
  sport,
  avatar,
  onTimelineClick,
  onScheduleClick,
  onMembersClick,
}: SpaceHeaderProps) => {
  const spaceSettingsBtn = (
    <DropdownButton
      btnClassName={styles.dropdown_btn_className}
      btnContent={<i className="ico ico-cog" />}
    >
      <SpaceSettings />
    </DropdownButton>
  );

  return (
    <PagePanel
      panelClassName={styles.page_panel}
      title={title || "Space"}
      subTitle={sport}
      avatar={avatar}
      headerChildren={displaySettings && spaceSettingsBtn}
    >
      <div className={styles.navbar_wrapper}>
        {onTimelineClick && (
          <TabButton
            label={"Timeline"}
            icon={<i className={cls("tab-icon ico ico-feed-o")} />}
            isActive={
              !window.location.pathname.includes("members") &&
              !window.location.pathname.includes("schedule")
            }
            onClick={onTimelineClick}
            className={styles.mobile}
            labelClassName={styles.tab_label}
          />
        )}
        {onScheduleClick && (
          <TabButton
            label={"Schedule"}
            icon={<i className={cls("tab-icon ico ico-time")} />}
            isActive={window.location.pathname.includes("schedule")}
            onClick={onScheduleClick}
            className={styles.mobile}
            labelClassName={styles.tab_label}
          />
        )}
        {onMembersClick && (
          <TabButton
            label={"Members"}
            icon={<i className={cls("tab-icon ico ico-tspace-o")} />}
            isActive={window.location.pathname.includes("members")}
            onClick={onMembersClick}
            className={styles.mobile}
            labelClassName={styles.tab_label}
          />
        )}
      </div>
    </PagePanel>
  );
};

export default SpaceHeader;
