import pdf_ico from "../../../../../public/images/ico_pdf.clean.svg";
import xlxx_ico from "../../../../../public/images/ico_xls.clean.svg";
import doc_ico from "../../../../../public/images/ico_doc.clean.svg";
import ppt_ico from "../../../../../public/images/ico_ppt.clean.svg";

const useDatafileIconsSvg = () => {
  const icons: { [key: string]: any } = {
    xlxx: xlxx_ico,
    xlsx: xlxx_ico,
    doc: doc_ico,
    docx: doc_ico,
    ppt: ppt_ico,
    pptx: ppt_ico,
    pdf: pdf_ico,
  };

  const dataFileIconHandler = (datafile: string): any => {
    if (datafile === undefined) {
      return;
    }

    return icons[datafile] || undefined;
  };

  return {
    dataFileIconHandler,
  };
};

export default useDatafileIconsSvg;
