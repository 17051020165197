/**
 * @module Reducers.Events
 *
 */
import { RequestError } from "typedefs";
import { EventAction } from "app/actions/events";
import { EventModel } from "app/models/EventModel";

import { reject, includes } from 'lodash';

export type EventsState = {
  data: EventModel[] | null;
  error: RequestError | null;
  pending: boolean;
  selected: EventModel | null
  newEvent?: EventModel;
  pendingCreate: boolean;
  pendingEdit: boolean;
  editSelected: boolean;
  showInfoPopup: boolean;
  infoPopupContent: {
    title?: string,
    status?: 'error' | 'success' | 'conflict',
    content?: string,
    subcontent?: string,
    payload?: any;
    action?: 'create' | 'edit';
    item?: EventModel,
  }
  confirmation: {
    show: boolean;
    content: string;
  };
};

const initialState: EventsState = {
  data: [],
  error: null,
  pending: false,
  selected: null,
  pendingCreate: false,
  pendingEdit: false,
  editSelected: false,
  showInfoPopup: false,
  infoPopupContent: {},
  confirmation: {
    show: false,
    content: null
  },
  // newEvent: {
  //   start: '2024-05-17T11:00:00.000-04:00',
  //   end: '2024-05-17T13:00:00.000-04:00',
  //   timeZone: 'America/New_York',
  //   studentProfiles: [],
  //   rate: 0,
  //   location: 'Range',
  //   type: 'lesson_schedule',
  // },
};

export default function listReducer(
  state = initialState,
  action: EventAction
): EventsState {
  switch (action.type) {
    case "@events.showInfoPopup":
      return { ...state, showInfoPopup: true, infoPopupContent: action.payload };
    case "@events.hideInfoPopup":
      return { ...state, showInfoPopup: false, infoPopupContent: {} };
    case "@events.edit.start":
      return { ...state, editSelected: true, newEvent: null };
    case "@events.edit.end":
      return { ...state, editSelected: false, selected: null, pendingEdit: false };
    case "@events.new.init":
      return { ...state, newEvent: action.payload, selected: null };
    case "@events.edit.pending":
      return { ...state, pendingEdit: true };
    case "@events.edit.pendingDone":
      return { ...state, pendingEdit: false };
    case "@events.create.pending":
      return { ...state, pendingCreate: true };
    case "@events.create.pendingDone":
      return { ...state, pendingCreate: false };
    case "@events.create.success":
      return { ...state, pendingCreate: false, data: [...state.data, ...action.payload], newEvent: null};
    case "@events.create.error":
      return { ...state, pendingCreate: false, error: action.payload, newEvent: null};
    case "@events.create.validationError":
      return { ...state, pendingCreate: false, error: action.payload };
    case "@events.edit.success":
      const { events, type } = action.payload;

      const newEventIds = events.map(e => e.id);

      const remainingEvents = reject(state.data, ev => {
        return ev.type === type && includes(newEventIds, ev.id);
      });

      return { ...state, data: [...remainingEvents, ...events], editSelected: false, selected: null, pendingEdit: false, pendingCreate: false };
    case "@events.edit.error":
      return { ...state, pendingEdit: false, error: action.payload, selected: null, editSelected: false };
    case "@events.edit.validationError":
      return { ...state, pendingEdit: false, error: action.payload};
    case "@events.new.clear":
      return { ...state, newEvent: null };
    case "@events.requestConfirmation":
      return { ...state, confirmation: { show: true, ...action.payload } };
    case "@events.dismissConfirmation":
      return { ...state, confirmation: { show: false, content: null } };
    case "@events.remove":
      const newEvents = reject(state.data, event => {
        return includes(action.payload, event.id);
      });

      return { ...state, data: newEvents, selected: null };
    case "@events.select":
      return { ...state, selected: action.payload };
    case "@events.deselect":
      return { ...state, selected: null, newEvent: null, editSelected: false, error: null };
    case "@events.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@events.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@events.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@events.clear":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
