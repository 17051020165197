/**
 * @module Reducers.User
 *
 */
import { RequestError } from 'typedefs';
import { UserAction } from 'app/actions/user';
import { UserModel } from 'app/models';

export type UserState = {
  self: UserModel | null;
  data: UserModel | null;
  resetPassword: boolean | null;
  error: RequestError | null;
  pending: boolean;
};

const initialState: UserState = {
  self: null,
  data: null,
  resetPassword: null,
  error: null,
  pending: false,
};

export default function userReducer(state = initialState, action: UserAction): UserState {
  switch (action.type) {
    case '@user.fetch.pending':
      return { ...state, error: null, pending: true };
    case '@user.fetch.success':
      return { ...state, self: action.payload, data: action.payload, pending: false };
    case '@user.fetch.done':
      return { ...state, pending: false };
      // return { ...state, self: action.payload, data: action.payload, pending: false };
    case '@user.fetch.error':
      return { ...state, error: action.payload, pending: false };
    case '@user.put.reset-password.success':
      return { ...state, resetPassword: true, pending: false };
    case '@user.set_scheduling_data':
      const schedulingPayload = {
        scheduling: {
          facility: action.payload
        }
      };
      const newSelf = { ...state.self, ...schedulingPayload };
      // @ts-ignore
      return { ...state, self: newSelf };
    default:
      return state;
  }
}
