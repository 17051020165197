import { RootState } from "typedefs";
import { useEffect } from "react";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchFacilityEvents } from "app/actions/events";
import { DateTime } from "luxon";

export const useEventsModel = (startDate: DateTime, endDate: DateTime) => {
  const eventActions = useActions({
    fetchFacilityEvents,
  });

  const start: string = startDate ? startDate.toISO() : null;
  const end: string = endDate ? endDate.toISO() : null;

  useEffect(() => {
    if (!start || !end) {
      return;
    }
    eventActions.fetchFacilityEvents({ start, end });
  }, [start, end]);

  const events = useSelector((state: RootState) => state.events);

  return { events };
};
