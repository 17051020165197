/**
 * @module Model.LessonRate
 *
 */
import { Model } from "typedefs";

export interface LessonRateResponse {
  id: number;
  coachProfileId: string;
  defaultRate: boolean;
  duration: string;
  durationInMinutes: number;
  location: string;
  rate: number;
  title: string;
}

export class LessonRateModel implements Model {
  id: number;
  coachProfileId: string;
  defaultRate: boolean;
  duration: string;
  durationInMinutes: number;
  location: string;
  rate: number;
  title: string;

  constructor(lessonRate: LessonRateResponse) {
    this.id = lessonRate.id;
    this.coachProfileId = lessonRate.coachProfileId;
    this.defaultRate = lessonRate.defaultRate;
    this.duration = lessonRate.duration;
    this.durationInMinutes = lessonRate.durationInMinutes;
    this.location = lessonRate.location;
    this.rate = lessonRate.rate;
    this.title = lessonRate.title;
  }
}
