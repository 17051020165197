import React, { useMemo, useCallback, useContext } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { isDate, debounce, range } from 'lodash';

import FormItem from "app/components/inputs/FormItem";
import TimeRangeInput from "app/components/inputs/TimeRangeInput";
import DateSelector from "./DateSelector";
import SessionTypeSelector from "./SessionTypeSelector";
import RecurrenceSelector from "./RecurrenceSelector";
import SchedulingUserSelector from 'app/components/SchedulingUserSelector';
import { schedulingFetchGet } from 'app/utils/request/scheduling/fetch';

import { errorMessageFor } from "./helpers";

import styles from "./styles.module.scss";

import { LessonRateModel } from 'app/models/scheduling/LessonRateModel';
import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';
import { UserContext, RoleType } from 'app/contexts/UserContext';
import { StudentProfileModel } from 'app/models/scheduling/StudentProfileModel';
import { FacilityModel } from 'app/models/scheduling/FacilityModel';

type PropsDef = {
  lessonRateOptions: LessonRateModel[];
  onDateChange: (date: Date) => void;
  coachProfile: CoachProfileModel;
  facility?: FacilityModel;
};

export default function SessionForm(props: PropsDef) {
  const { t } = useTranslation();
  const { lessonRateOptions, onDateChange, coachProfile, facility } = props;

  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchTimeZone = watch('timeZone');
  const watchStartTime = watch('start');
  const watchLessonRateId = watch('lessonRateId');
  const watchCoaches = watch('coachProfiles');
  const watchStudents = watch('studentProfiles');

  const luxonStartAt = useMemo(() => {
    return DateTime.fromISO(watchStartTime, { zone: watchTimeZone });
  }, [watchStartTime]);

  const disabledEndHours = useCallback(() => {
    const disabled = range(0, 23).filter((h) => h < luxonStartAt.hour);

    return disabled;
  }, [watchStartTime]);

  const disabledEndMinutes = useCallback(
    (endHour: number) => {
      const startHour = luxonStartAt.hour;
      const startMinute = luxonStartAt.minute;
      if (endHour > startHour) {
        return [];
      }

      return [0, 15, 30, 45].filter((m) => m <= startMinute);
    },
    [watchStartTime]
  );

  const onStartTimeChange = (changeEvent: any) => {
    const newStartAt = DateTime.fromISO(changeEvent.target.value, {
      zone: watchTimeZone,
    });
    const sessionType = lessonRateOptions.find(
      (lr) => lr.id === watchLessonRateId
    );
    const durationInMinutes = sessionType
      ? parseInt(sessionType.duration, 10)
      : 15;

    if (sessionType && durationInMinutes > 0) {
      const newEndTime = newStartAt.plus({ minutes: durationInMinutes });
      setValue("end", newEndTime.toISO());
    }
  };

  const onEndTimeChange = (changeEvent: any) => {
    const newEndAt = DateTime.fromISO(changeEvent.target.value, {
      zone: watchTimeZone,
    });
    const sessionType = lessonRateOptions.find(
      (lr) => lr.id === watchLessonRateId
    );

    if (!sessionType || !luxonStartAt.isValid) {
      setValue("end", changeEvent.target.value);
      return;
    }

    const durationInMinutes = parseInt(sessionType.duration, 10) || 0;
    const expectedEndTime = luxonStartAt.plus({ minutes: durationInMinutes });

    if (!expectedEndTime.equals(newEndAt)) {
      setValue("lessonRateId", null);
    }

    setValue("end", changeEvent.target.value);
  };

  const onLessonRateChange = (changeEvent: any) => {
    const sessionType = changeEvent.target.value;
    setValue("lessonRateId", sessionType.id);
    setValue("rate", sessionType.rate);
    setValue("location", sessionType.location);

    const durationInMinutes = parseInt(sessionType.duration, 10);
    if (luxonStartAt.isValid) {
      const newEndTime = luxonStartAt.plus({ minutes: durationInMinutes });
      setValue("end", newEndTime.toISO());
    }
  };

  const onRateChange = (changeEvent: any) => {
    const newRate = changeEvent.target.value;
    setValue("rate", newRate);

    if (!watchLessonRateId) {
      return;
    }

    const sessionType = lessonRateOptions.find(
      (lr) => lr.id === watchLessonRateId
    );

    if (!sessionType) {
      return;
    }

    if (sessionType.rate !== newRate) {
      setValue("lessonRateId", null, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const user = useContext(UserContext);
  const allowCustomSession = user.isAdmin || user.role === RoleType.ADMIN || (user.role === RoleType.COACH && watchCoaches && watchCoaches[0]?.id === user.id);
  const sessionType = lessonRateOptions.find(lr => lr.id === watchLessonRateId);
  const allowRecurring = user.isAdmin || (user.role === RoleType.COACH && watchCoaches && watchCoaches[0]?.id === user.id);

  const loadStudents = debounce((inputValue, setValue) => {
    const exceptIds = watchStudents.value?.map((s: StudentProfileModel) => s.id) || [];
    schedulingFetchGet(`/facilities/${facility.id}/student_profiles`, { query: inputValue, per_page: 40, except: exceptIds })
      .then(setValue);
  }, 250);

  return (
    <div className={`${styles.form_content} form_fields`}>
      <FormItem error={errorMessageFor(errors, 'studentProfiles')} className={styles.input_field}>
        <label>{t('Participating Athletes')}</label>
        <SchedulingUserSelector
          {...register("studentProfiles", { validate: ids => ids.length > 0 })}
          control={control}
          loadOptions={loadStudents}
          placeholder={'Select Athletes'}
          multiSelect
        />
      </FormItem>

      <hr className={styles.separator} />

      <FormItem className={styles.input_field}>
        <label>{t("Date")}</label>
        <DateSelector
          {...register("date", { onChange: onDateChange, validate: isDate })}
          control={control}
        />
      </FormItem>

      <div className={styles.form_row}>
        <FormItem
          error={errorMessageFor(errors, "start")}
          className={styles.input_field}
        >
          <label>{t("Start Time")}</label>
          <TimeRangeInput
            {...register("start", { onChange: onStartTimeChange })}
            control={control}
            timeZone={watchTimeZone}
          />
        </FormItem>


        {allowCustomSession && (
          <FormItem error={errorMessageFor(errors, 'end')} className={styles.input_field}>
            <label>{t('End Time')}</label>
            <TimeRangeInput {...register('end', { onChange: onEndTimeChange })} control={control} timeZone={watchTimeZone} disabledHours={disabledEndHours} disabledMinutes={disabledEndMinutes} />
          </FormItem>
        )}

        {!allowCustomSession && (
          <FormItem className={styles.input_field}>
            <label>{t('Duration')}</label>
            <input type="text" value={sessionType?.duration || ''} disabled />
          </FormItem>
        )}
      </div>

      {allowRecurring && <RecurrenceSelector />}

      <hr className={styles.separator} />

      <FormItem className={styles.input_field}>
        <label>{t("Session")}</label>
        <SessionTypeSelector
          key={watchCoaches[0]?.id}
          {...register('lessonRateId', { onChange: onLessonRateChange })}
          control={control}
          options={lessonRateOptions}
          allowCustomSession={allowCustomSession}
        />
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t("Rate")}</label>
        <div className={styles.currency_container}>
          <span>{coachProfile?.currencySymbol}</span>
          <input
            {...register("rate", { onChange: onRateChange })}
            placeholder="0"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t("Location")}</label>
        <input type="text" {...register("location")} />
      </FormItem>

      <FormItem className={styles.input_field}>
        <label>{t("Notes")}</label>
        <textarea
          style={{ height: 100, resize: "none" }}
          {...register("notes")}
          placeholder={t("Add note here")}
        />
      </FormItem>
    </div>
  );
}
