import { RootState } from "typedefs";
import { useSelector } from "app/utils/hooks";
import { FacilityModel } from "app/models/scheduling/FacilityModel";

const useCurrentFacility = (): { data: FacilityModel, pending: boolean } => {
  const currentFacility = useSelector((state: RootState) => state.user?.self?.scheduling?.facility);

  return { data: currentFacility, pending: !currentFacility };
};

export default useCurrentFacility;
