import { createPost } from "app/actions/postingForm";
import { clearSegments, fetchSegmentById } from "app/actions/segments";
import { PostingModel } from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import usePlanFeature from "app/utils/services/plan_feature/user";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "typedefs";

export const usePostToSegmentModel = () => {
  const history = useHistory();
  const { segmentId } = useParams<{ id: string; segmentId: string }>();
  const feedPostActions = useActions({
    createPost,
    fetchSegmentById,
    clearSegments,
  });
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );

  const { t } = useTranslation();
  const [headerTitle, setHeaderTitle] = useState<string>("");

  useEffect(() => {
    feedPostActions.fetchSegmentById(segmentId).then((segment) => {
      if (segment && segment.name) {
        setHeaderTitle(segment.name);
      }
    });

    return () => feedPostActions.clearSegments();
  }, []);

  const { canSchedulePosts, canAddMedia, canPostMedia } = usePlanFeature(user);

  // check for user's permissions for uploading media file to posting form
  const canUploadMedia = () => {
    if (!user) {
      return null;
    }
    return canAddMedia && canPostMedia;
  };

  // posting form submit handler
  const onSubmit = (data: PostingModel) => {
    // check if location(s) are selected in redux to use id from it
    if (segmentId) {
      feedPostActions
        .createPost({ ...data, segment_ids: [segmentId] })
        .catch((err) => {
          throw err;
        })
        .then(() => {
          history.goBack();
        });
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  return {
    canUploadMedia,
    t,
    user,
    canSchedulePosts,
    onSubmit,
    onCancel,
    headerTitle,
  };
};
