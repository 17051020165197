import React from "react";
import {useTranslation} from "react-i18next";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import DeleteIcon from "../../../../public/images/delete-icon.svg";
import DOMPurify from "dompurify";

interface RemoveFacilityUserProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  userName: string;
}

const RemoveFacilityUser = ({ isOpen, onClose, onDelete, userName }: RemoveFacilityUserProps) => {
  const { t } = useTranslation();
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <img style={{width: '40px', height: '40px', display: 'block', margin: '0 auto'}} src={DeleteIcon} alt="info"
           onClick={onClose}/>
      <div className={styles.modal_header}>
        <h2>{t("Delete User")}</h2>
      </div>
      <div className={styles.modal_content}>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t('Are you sure you want to delete <strong>{{name}}</strong>?', {
                name: userName,
              }),
            )
          }}
        />
        <div className={styles.buttonGroup}>
          <button type="button" className={styles.confirmButton} onClick={onDelete}>
            {t("Delete")}
          </button>
          <button type="button" className={styles.cancelButton} onClick={onClose}>
            {t("Cancel")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default RemoveFacilityUser;
