import React from 'react';
import {Controller, useForm} from "react-hook-form";
import styles from "../styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import CloseIcon from "../../../../public/images/close.svg";
import {useTranslation} from "react-i18next";
import Select from "react-select";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isAdmin: boolean;
}

interface AddFacilityUser {
  isOpen: boolean;
  onClose: () => void;
  users: User[];
  onAddUser: (user: User) => void;
}

const AddFacilityUser = ({ isOpen, onClose, users, onAddUser }: AddFacilityUser) => {
  const {
    register, handleSubmit, formState: { errors }, reset, control, trigger, setError
  } = useForm<User>({
    defaultValues: {
      role: 'coach',
    }
  });
  const { t } = useTranslation();

  const emailIsUnique = (email: string) => {
    return !users.find((user: User) => user.email === email);
  };

  const onSubmit = (data: User) => {
    if(!emailIsUnique(data.email)) {
      setError("email", { type: "manual", message: "Email is already in use" });
    } else {
      const formatedData = {
        ...data,
        isAdmin: data.role === "coach_admin",
        role: data.role === "coach_admin" ? "coach" : data.role,
      };

      onAddUser(formatedData);
      onClose();
      reset({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        isAdmin: false,
      });
    }
  };

  const IndicatorSeparator = (): JSX.Element | null => null;

  const roles = [
    { value: 'admin', label: 'Admin', isAdmin: false },
    { value: 'coach', label: 'Coach', isAdmin: false },
    { value: 'coach_admin', label: "Coach - Admin", isAdmin: true },
    { value: 'scheduler', label: 'Scheduler', isAdmin: false },
  ];

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.modal_header}>
        <h2>{t('Add User')}</h2>
        <img className={styles.modal_status_icon} src={CloseIcon} alt="info" onClick={onClose}/>
      </div>
      <div className={styles.modal_content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="role">{t('User Type')}</label>
          <div style={{marginBottom: '10px'}}>
            <Controller
              control={control}
              name="role"
              render={({field}) => (
                <Select
                  id="role"
                  value={roles.find(option => option.value === field.value) || roles[1]}
                  onChange={(selectedOption) => {
                    field.onChange((selectedOption as { value: string; label: string })?.value || "");
                    trigger("role");
                  }}
                  options={roles}
                  components={{IndicatorSeparator}}
                  placeholder={t('Select Role')}
                  isSearchable={false}
                />
              )}/>
          </div>
          <div className={styles.form_row}>
            <label htmlFor="first_name">{t('First Name')}</label>
            <input
              id="first_name"
              {...register('firstName', {required: "First name is required"})}
            />
            {errors.firstName && <span>{errors.firstName.message}</span>}
          </div>
          <div className={styles.form_row}>
            <label htmlFor="last_name">{t('Last Name')}</label>
            <input
              id="last_name"
              {...register('lastName', {required: "Last name is required"})}
            />
            {errors.lastName && <span>{errors.lastName.message}</span>}
          </div>
          <div className={styles.form_row}>
            <label htmlFor="email">{t('Email')}</label>
            <input
              id="email"
              {...register('email', {required: "Email is required"})}
            />
            {errors.email && <span>{errors.email.message}</span>}
          </div>
          <div className={styles.buttonGroup}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={onClose}
            >
              {t("Cancel")}
            </button>
            <button type="submit" className={styles.confirmButton}>
              {t("Save")}
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
};

export default AddFacilityUser;
