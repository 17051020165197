/**
 * @module Components.Routes
 *
 */
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useForm, FormProvider } from 'react-hook-form';

import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";

import { EventModel } from 'app/models/EventModel';
import { LessonRateModel } from 'app/models/scheduling/LessonRateModel';
import { CoachProfileModel } from 'app/models/scheduling/CoachProfileModel';

import EventTypeSelector from "./EventTypeSelector";
import SessionForm from "./SessionForm";
import ScheduleBlockForm from "./ScheduleBlockForm";

import styles from './styles.module.scss';
import CloseIcon from "../../../../public/images/close.svg";
import { useTranslation } from 'react-i18next';
import { FacilityModel } from 'app/models/scheduling/FacilityModel';

type PropsDef = {
  event: EventModel;
  lessonRateOptions: LessonRateModel[];
  onCancel: () => void;
  onSave: (event: EventModel) => void;
  submitting: boolean;
  errors: any;
  coachProfile: CoachProfileModel;
  facility: FacilityModel;
};

export default function EventForm({
  event,
  onCancel,
  lessonRateOptions,
  onSave,
  errors,
  submitting=false,
  coachProfile,
  facility,
}: PropsDef) {
  const { t } = useTranslation();

  const methods = useForm({
    defaultValues: {
      ...event,
      coachProfiles: [coachProfile],
    },
    mode: 'onChange',
  });

  const {
    control,
    register,
    unregister,
    handleSubmit,
    setValue,
    watch,
    formState,
    clearErrors,
    setError,
    getValues,
  } = methods;

  useEffect(() => {
    clearErrors();

    if (!errors) {
      return;
    }

    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        const error = errors[key];
        if (error) {
          const field = key === 'studentProfileIds' ? 'studentProfiles' : key;
          const message = error.join(', ');
          // @ts-ignore:next-line
          setError(field, { type: 'custom', message });
        }
      });
    }
  }, [errors]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const fadeForModal = showConfirmation;

  const watchEventType = watch('type');

  const onDateChange = (changeEvent) => {
    const date = changeEvent.target.value;

    setValue('date', date, { shouldValidate: true, shouldDirty: true });
    const startTime = DateTime.fromISO(getValues('start'))
                              .setZone(event.timeZone)
                              .set({
                                year: date.getFullYear(),
                                month: date.getMonth() + 1,
                                day: date.getDate()
                              });
    const endTime = DateTime.fromISO(getValues('end'))
                            .setZone(event.timeZone)
                            .set({
                              year: date.getFullYear(),
                              month: date.getMonth() + 1,
                              day: date.getDate()
                            });

    setValue('start', startTime.toISO(), { shouldDirty: true });
    setValue('end', endTime.toISO(), { shouldDirty: true });
  };

  const onSubmit = (data) => {
    let eventData;
    if (data.type === 'lesson_schedule') {
      eventData = {
        ...data,
        studentProfileIds: data.studentProfiles.map((student) => student.id),
        rate: !data.rate ? 0 : data.rate,
      };
      delete eventData.studentProfiles;
    } else {
      eventData = data;
    }

    eventData = {
      ...eventData,
      start: DateTime.fromISO(eventData.start).setZone(eventData.timeZone).set({
        year: eventData.date.getFullYear(),
        month: eventData.date.getMonth() + 1,
        day: eventData.date.getDate(),
      }).toISO(),
      end: DateTime.fromISO(eventData.end).setZone(eventData.timeZone).set({
        year: eventData.date.getFullYear(),
        month: eventData.date.getMonth() + 1,
        day: eventData.date.getDate(),
      }).toISO(),
    };
    onSave(eventData);
  };

  const isEdit = !!event.id;
  const isNew = !isEdit;

  const title = isEdit ? 'Edit Event' : 'New Event';

  const onEventTypeChange = (changeEvent: any) => {
    const eventType = changeEvent.target.value;
    if (eventType === 'lesson_schedule') {
      unregister('title');
      unregister('private');
    }

    if (eventType === 'coach_schedule_block') {
      unregister('studentProfiles', { keepDefaultValue: true });
      unregister('lessonRateId', { keepDefaultValue: true });
      unregister('rate', { keepDefaultValue: true });
      unregister('location', { keepDefaultValue: true });
    }

    setValue('type', eventType);
  };

  const onDismiss = () => {
    const hasDirtyFields = Object.keys(formState.dirtyFields).length > 0;
    if (hasDirtyFields) {
      setShowConfirmation(true);
    } else {
      onCancel();
    }
  };

  return (
    <>
      <ModalLayout isOpen classNames={`${styles.modal} ${fadeForModal ? styles.fade_for_confirmation : ''}  event_form_container`}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <img className={styles.modal_status_icon} src={CloseIcon} alt="info" onClick={onDismiss} />
        </div>

        <div className={`${styles.event_form}`}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isNew && <EventTypeSelector {...register('type', { onChange: onEventTypeChange })} control={control} allowUnavailable />}

              {watchEventType === 'coach_schedule_block' ? (
                <ScheduleBlockForm onDateChange={onDateChange} />
              ) : (
                <SessionForm
                  lessonRateOptions={lessonRateOptions}
                  onDateChange={onDateChange}
                  coachProfile={coachProfile}
                  facility={facility}
                />
              )}

            </form>
          </FormProvider>
        </div>

        <div className={styles.form_actions}>
          <button className={`btn btn-outline-secondary`} onClick={onDismiss} type="button">{t("Cancel")}</button>
          <button disabled={!formState.isValid || submitting} className={`btn btn-primary`} type="button" onClick={handleSubmit(onSubmit)}>
            {submitting ? t('Saving...') : t('Save')}
          </button>
        </div>
      </ModalLayout>

      <ModalLayout isOpen={showConfirmation} classNames={`${styles.modal2} confirm_cancel`}>
        <div className={styles.confirmation_modal_content}>
          <div>
            {t("Are you sure you want to discard this event?")}
          </div>
          <div className={styles.confirmation_actions}>
            <button className="btn btn-secondary" type="button" onClick={() => setShowConfirmation(false)}>Cancel</button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                setShowConfirmation(false);
                onCancel();
              }}>
                {t("Discard")}
            </button>
          </div>
        </div>
      </ModalLayout>
    </>
  );
}
