import UserModel from "app/models/UserModel";
import { UserState } from "app/reducers/user";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";

type useSubscriptionBadgeModelType = {
  self: UserModel;
  hasActiveProSubscription: () => boolean;
  hasActiveAnalyzeSubscription: () => boolean;
  hasActiveAcademySubscription: () => boolean;
  hasActivePlusSubscription: () => boolean;
  hasOrganization: () => boolean;
  shouldShowTrialText: () => boolean;
  trialWarningClass: () => boolean;
};

export const useSubscriptionBadgeModel = (): useSubscriptionBadgeModelType => {
  const { self } = useSelector((state: RootState): UserState => state.user);

  const isPlanActive = (prefix: string): boolean => {
    return (
      !!self &&
      !self.hasTrialSubscription &&
      self.plan?.planCode?.startsWith(prefix) &&
      (self.subscription?.status === "active" || self.plan?.licensed)
    );
  };

  const hasActiveProSubscription = (): boolean => isPlanActive("coachpro+");
  const hasActiveAnalyzeSubscription = (): boolean => isPlanActive("analyze");
  const hasActiveAcademySubscription = (): boolean => isPlanActive("academy");
  const hasActivePlusSubscription = (): boolean => isPlanActive("coach+");

  const hasOrganization = (): boolean => !!self?.traits?.company?.name;

  function shouldShowTrialText(): boolean {
    if (!self) {
      return false;
    }
    const isTrialExpired =
      !self.hasCoachPlusSubscription &&
      self.subscription.status === "trial_expired";

    return self.hasTrialSubscription || isTrialExpired;
  }

  const trialWarningClass = (): boolean => {
    if (!self) {
      return false;
    }

    return (
      (self.hasTrialSubscription && self.plan?.trialDaysLeft <= 3) ||
      self.subscription?.status === "trial_expired"
    );
  };

  return {
    self,
    hasActiveProSubscription,
    hasActiveAnalyzeSubscription,
    hasActiveAcademySubscription,
    hasActivePlusSubscription,
    hasOrganization,
    shouldShowTrialText,
    trialWarningClass,
  };
};
