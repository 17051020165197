import { useActions, useSelector } from "app/utils/hooks";
import { useTranslation } from "react-i18next";
import { shallowEqual } from "react-redux";
import { RootState } from "typedefs";
import {
  fetchCoachProfile,
  updateCoachProfile,
} from "app/actions/coachNowCoachProfile";
import { fetchSelf, editProfileSettings } from "app/actions/user";
import { useCallback, useEffect, useState } from "react";
import CoachProfileModel from "app/models/CoachProfileModel";
import { IUserPhone } from "app/interfaces/UserTypes";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";

export const useCoachProfileModel = () => {
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { data: coachData } = useSelector(
    (state: RootState) => state.coachProfile,
    shallowEqual,
  );
  const actions = useActions({
    fetchCoachProfile,
    fetchSelf,
    updateCoachProfile,
    editProfileSettings,
  });

  const [toastAlert, setToastAlert] = useState(null);
  const [userState, setUserState] = useState<CoachProfileModel>(
    coachData || {},
  );
  const [uploadedAvatar, setUploadedAvatar] = useState<{
    url?: string;
    id?: string;
  }>(null);
  const [uploadedCover, setUploadedCover] = useState<string | null>(null);
  const [phoneValue, setPhoneValue] = useState<string>("");

  useEffect((): (() => void) => {
    let isMounted = true;

    actions.fetchCoachProfile(self?.id).then((res: any): void => {
      if (isMounted && res) {
        setUserState(res);
        if (res?.coverUrl) {
          setUploadedCover(res?.coverUrl);
        }
        if (res?.phone && res?.phoneCountry) {
          const fullPhone = `+${res.phoneCountry}${res.phone}`;
          setPhoneValue(fullPhone);
        }
      }
    });

    return (): void => {
      isMounted = false;
    };
  }, [actions, self]);

  const onUpload = (avatar: { url?: string; id?: string }) => {
    try {
      setUploadedAvatar(avatar);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const onCoverChange = (avatar: { id: string; url: string }) => {
    setUploadedCover(avatar?.url);
    setUserState({
      ...userState,
      cover_id: avatar?.id,
    });
  };

  const onPhoneChange = (
    value: string,
    data: IUserPhone,
    _event: React.FormEvent<HTMLInputElement>,
    // formattedValue: string,
  ) => {
    const phone = value.substring(data?.dialCode?.length);
    const phoneCountry = data?.countryCode?.toUpperCase();
    console.log(phone);
    setPhoneValue(value);
    setUserState({
      ...userState,
      phone: value,
      phoneCountry,
    });
  };

  const updateUserData = ({
    type,
    value,
  }: {
    type: string;
    value: string | boolean;
  }) => {
    setUserState({ ...userState, [type]: value });
  };

  const deleteHandler = useCallback(() => {
    customPrompt({
      message: t("Are you sure you want to delete the cover image?"),
      onYes: () => {
        setUploadedCover(null);
        if (!userState?.coverUrl) {
          setUserState({
            ...userState,
            cover_id: null,
          });
        } else {
          actions.updateCoachProfile({ remove_cover: true }).then(() => {
            actions.fetchCoachProfile(self?.id).then(() => {
              setToastAlert(t("Your account was successfully updated."));
            });
          });
        }
      },
      onCancel: () => {
        return;
      },
    });
  }, [actions, self, userState, t]);

  const updateUser = () => {
    // if (!userState && !uploadedAvatar?.url) {
    //   return;
    // }
    if (uploadedAvatar?.url) {
      actions
        .editProfileSettings({
          avatar: uploadedAvatar?.url,
          avatarId: uploadedAvatar?.id,
        })
        .then(() => actions.fetchSelf());
    }
    if (userState) {
      actions.updateCoachProfile(userState).then(() => {
        actions.fetchCoachProfile(self?.id).then(() => {
          setToastAlert(t("Your account was successfully updated."));
        });
      });
    }
  };

  return {
    t,
    self,
    coachData,
    userState,
    updateUserData,
    uploadedAvatar,
    onCoverChange,
    uploadedCover,
    onUpload,
    onPhoneChange,
    phoneValue,
    updateUser,
    setToastAlert,
    toastAlert,
    deleteHandler,
  };
};
