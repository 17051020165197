import React from "react";
import Post from "../Post";
import PostModel from "app/models/PostModel";
import { Loading } from "../Wrappers";
import { TimelinePostContainerProps } from "../TimelinePostsContainer";
import NoScheduledPosts from "../NoResults/NoScheduledPosts";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import i18n from "app/i18n";

const ScheduledPagePostsContainer: React.FC<TimelinePostContainerProps> = ({
  displayPostLocations,
  posts,
  pending,
  onPostDelete,
  onPostUpdate,
  containerRef,
  hideReply,
  hideLikes,
}: TimelinePostContainerProps) => {
  const { t } = useTranslation();

  if (!self) {
    return null;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    } as any;
    const formattedDate = date.toLocaleDateString(i18n.language, dateOptions);

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    if (!dateString) {
      return <strong>{t("Publish Immediately")}</strong>;
    }

    return (
      <div className={styles.date_wrapper}>
        <i className="ico ico-time" />
        &nbsp;
        <strong>{formattedDate}</strong> {t("publish at")}{" "}
        <strong>
          {formattedHours}:{formattedMinutes} {ampm}
        </strong>
      </div>
    );
  };

  if (posts?.length === 0) {
    if (!pending) {
      return <NoScheduledPosts />;
    } else {
      return <Loading isLoading loadType="spinner" />;
    }
  }

  return (
    <>
      {posts &&
        posts.map((post: PostModel) => {
          return (
            <div key={post.id}>
              {post?.scheduledAt && formatDate(post?.scheduledAt)}
              <Post
                key={post.id}
                post={post}
                user={self}
                onEdit={onPostUpdate}
                onEditTags={onPostUpdate}
                onRemove={onPostDelete}
                displayLocation={displayPostLocations}
                dropdownButtonClassName={styles.dropdown_button}
                hideReply={hideReply}
                hideLikes={hideLikes}
              />
            </div>
          );
        })}
      {pending && posts?.length > 0 && <Loading isLoading loadType="spinner" />}
      <div ref={containerRef} />
    </>
  );
};

export default ScheduledPagePostsContainer;
