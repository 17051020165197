import { useCallback, useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "typedefs";
import { SidebarProps } from "../index.types";
import { useHistory } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
// import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";

const useSidebarDropdownModel = ({ user }: SidebarProps) => {
  const { t } = useTranslation();
  // const { data: organizations } = useSelector(
  //   (state: RootState) => state.organizations,
  //   shallowEqual,
  // );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const user = useSelector((state: RootState) => state.user.self);
  const history = useHistory();

  const [showModal, setPaywallModal] = useState(false);

  const onPaywallOpen = useCallback(() => {
    setPaywallModal(true);
  }, []);

  const onPaywallClose = useCallback(() => {
    setPaywallModal(false);
  }, []);

  const listData = [
    {
      title: t("Account Settings"),
      isBold: false,
      display: true,
      onClickFunc: () => history.push("/account/edit"),
    },
    {
      title: t("Organizations"),
      isBold: false,
      display: user?.traits?.["Organization ID"],
      // display organizations if there is more than 0
      onClickFunc: () => history.push("/organizations"),
    },
    {
      title: t("Tags"),
      isBold: false,
      display: true,
      onClickFunc: () => history.push("/account/tags"),
    },
    {
      title: t("Get Paid"),
      isBold: true,
      display: user?.kind === "coach",
      onClickFunc: () => goToLocation("https://coachnow.io/coachnowpartners"),
    },
    {
      title: t("Benefits"),
      isBold: true,
      display: user?.hasCoachPlusSubscription && !user.hasTrialSubscription,
      onClickFunc: () => history.push("/benefits"),
    },
    {
      title: t("Upgrade to CoachNow PRO"),
      isBold: true,
      display:
        !user?.hasCoachPlusSubscription &&
        user?.kind === "coach" &&
        user?.plan?.name?.toLowerCase() !== "academy",
      onClickFunc: () => onPaywallOpen(),
    },
  ];

  const modalHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      setIsOpen(false); // click inside
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, dropdownRef]);

  const goToLocation = (location: string) => {
    window.open(location);
  };

  return {
    modalHandler,
    dropdownRef,
    isOpen,
    listData,
    showModal,
    onPaywallClose,
    onPaywallOpen,
  };
};

export default useSidebarDropdownModel;
