import React from 'react';
import Async from 'react-select/async';
import { uniqBy } from 'lodash';
import { useController } from 'react-hook-form';

import Option from "./Option";
import SingleValue from "./SingleValue";
import MultiValue from "./MultiValue";
import SelectionList from "./SelectionList";

import { SelectStyles } from 'app/routes/Calendar/EventForm/constants';

type PropsDef = {
  name: string;
  control: any;
  placeholder: string;
  loadOptions: (inputValue: string, setValue: (value: any[]) => void) => void;
  multiSelect: boolean;
};

const onMultiSelect = (selectedUser: any, field: any) => {
  const filteredUsers = field.value.filter((s: { id: number; }) => s.id !== selectedUser.id);

  if (filteredUsers.length === field.value.length) { // adding user
    field.onChange(uniqBy(field.value.concat([selectedUser]), 'id'));
  } else {
    field.onChange(filteredUsers);
  }
};


const SchedulingUserSelector = React.forwardRef((props: PropsDef, _ref) => {
  const { name, control, placeholder, loadOptions, multiSelect } = props;

  const { field } = useController({ name, control });

  const onRemove = (userId: number) => {
    const newUsers = field.value.filter(user => {
      return user.id !== userId;
    });

    field.onChange(newUsers);
  };

  const hasUsers = field.value.length > 0;
  const showSelectionList = hasUsers && multiSelect;

  const ValueComponent = multiSelect ? MultiValue : SingleValue;

  return (
    <div>
      <Async
        styles={SelectStyles}
        defaultOptions
        getOptionLabel={opt => `${opt.firstName} ${opt.lastName}`}
        getOptionValue={opt => opt.id}
        loadingMessage={() => null}
        components={{ Option, SingleValue: ValueComponent, LoadingIndicator: () => null }}
        loadOptions={loadOptions}
        onChange={value =>  {
          if (multiSelect) {
            onMultiSelect(value, field);
          } else {
            field.onChange([value]);
          }
        }}
        placeholder={placeholder}
        value={field.value}
        isSearchable={multiSelect}
      />

      {showSelectionList && <SelectionList selection={field.value} onRemove={onRemove} />}
    </div>
  );
});

export default SchedulingUserSelector;
