import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { fetchMediaById } from "app/actions/media-library-data";
import { useActions } from "app/utils/hooks";
import VideoPostItem from "./VideoPostItem";
import ImagePostItem from "./ImagePostItem";
import AudioPostItem from "./AudioPostItem";
import DataFilePostItem from "./DataFilePostItem";

interface Props {
  mediaData: any;
  onClick: () => void;
  isShowingMore?: boolean;
  shouldShowMore?: boolean;
  onClickLast?: (type: boolean) => void;
  count?: number;
}

export const PostItem = ({
  mediaData,
  onClick,
  isShowingMore = false,
  onClickLast,
  count,
  shouldShowMore,
}: Props) => {
  const mediaActions = useActions({ fetchMediaById });
  const [postMedia, setPostMedia] = useState(mediaData);

  useEffect(() => {
    const fetchMedia = () => {
      if (postMedia?.id && !postMedia?.processed) {
        mediaActions.fetchMediaById(postMedia?.id).then((res) => {
          if (res?.processed) {
            setPostMedia(res);
          }
        });
      }
    };

    const pollingInterval = setInterval(() => {
      fetchMedia();

      if (postMedia?.processed) {
        clearInterval(pollingInterval);
      }
    }, 5000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [postMedia, mediaActions]);

  const renderMedia = () => {
    const showMoreOverlay = (
      <div
        className={cls(styles.overlay, {
          [styles.overlay_show]: !isShowingMore,
        })}
        onClick={() => onClickLast && onClickLast(true)}
      >
        <span>+{count} more</span>
      </div>
    );

    if (postMedia?.type === "video") {
      return (
        <VideoPostItem
          mediaData={mediaData}
          shouldShowMore={shouldShowMore}
          onClick={onClick}
          showMoreOverlay={showMoreOverlay}
        />
      );
    }

    if (postMedia?.type === "image") {
      return (
        <ImagePostItem
          mediaData={mediaData}
          shouldShowMore={shouldShowMore}
          onClick={onClick}
          showMoreOverlay={showMoreOverlay}
        />
      );
    }

    if (postMedia?.type === "datafile") {
      return (
        <DataFilePostItem
          mediaData={postMedia}
          shouldShowMore={shouldShowMore}
          showMoreOverlay={showMoreOverlay}
        />
      );
    }

    if (postMedia?.type === "audio") {
      return (
        <AudioPostItem
          shouldShowMore={shouldShowMore}
          onClick={onClick}
          showMoreOverlay={showMoreOverlay}
        />
      );
    }

    return null;
  };

  return renderMedia();
};
