import React, {useState, useEffect, useContext} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { cls } from "app/utils";
import { Loading } from "app/components/Wrappers";
import PagePanel from "app/components/PagePanel";

import { useEventsModel } from './indexModel';

import styles from "./styles.module.scss";

import NoSession from "./NoSession";
import { schedulingFetchDelete } from "../../utils/request/scheduling/fetch";
import SessionCard from "./SessionCard";
import InfoModal from "app/routes/Calendar/InfoModal";
import { removeEvents } from 'app/actions/events';

import {
  initBookSession,
  resetBookSession,
} from 'app/actions/scheduling/bookSession';
import {UserContext} from "app/contexts/UserContext";
import {toast} from "react-toastify";

type ConfirmationDialogData = {
  show?: boolean;
  title?: string;
  status?: 'success' | 'error';
  content?: string;
};

export default function Sessions() {
  const dispatch = useDispatch();
  const user = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(resetBookSession());
  }, []);

  if(user.kind !== 'athlete') {
    toast.error(t("You do not have access to this page."));
    history.push("/");
    return null;
  }

  const {
    events,
  } = useEventsModel(
    DateTime.now(),
    DateTime.now().plus({ years: 1 }),
  );
  const sessions = events.data.filter((item) => item.type !== 'program');

  const [confirmationDialog, setConfirmationDialog] = useState<ConfirmationDialogData>({});

  if (events.pending) {
    return <Loading isLoading loadType="spinner" />;
  }

  const onEditSession = (session) => {
    const startHour = DateTime.fromISO(session.start).setZone(session.timeZone);
    const endHour = DateTime.fromISO(session.end).setZone(session.timeZone);

    dispatch(initBookSession({
      ...session,
      selectedSession: session,
      time: {
        startHour: startHour.toMillis(),
        endHour: endHour.toMillis(),
        timeZone: session.timeZone,
        coachId: session.coachProfile.id,
      }
    }));
    history.push('/sessions/edit');
  };

  const onCancelSession = (session, closeModal) => {
    schedulingFetchDelete(`bookings/${session.id}`)
      .then(() => {
        setConfirmationDialog({
          title: t('Session Canceled'),
          content: t('This session has been successfully canceled.'),
          status: 'success',
          show: true,
        });
        dispatch(removeEvents([session.id]));
        closeModal();
      }).catch(errors => {
        closeModal();
        setConfirmationDialog({
          title: t('Could not cancel session'),
          content: errors.errors[0].details[0].message,
          status: 'error',
          show: true,
        });
      });

  };

  const handleCalendarSettings = () => {
    history.push("/calendar/integration");
  };

  const handleNewSession = () => {
    history.push("/sessions/new");
  };

  return (
    <div className={`${styles.container} sessions-page-container`}>
      <PagePanel
        title={t("Upcoming Sessions")}
        url="/sessions/new"
        headerChildren={(
          <div className={styles.page_panel}>
            <button  type="button" className="btn btn-primary" onClick={handleNewSession}>{t("Book")}</button>
            <button type="button" className={cls(styles.page_panel_settings_button, 'btn', 'btn-outline-secondary')} onClick={handleCalendarSettings}>
              <i className="ico ico-cog" />
            </button>
          </div>
        )}
        panelClassName={styles.page_panel}
      />
      <div className={`${sessions.length > 0 ? styles.session_cards : ''} `}>
        {
          sessions.length > 0 ?
            sessions.map((session, index) => (
              <SessionCard
                key={session.id}
                session={session}
                first={index === 0}
                onEditSession={onEditSession}
                onCancelSession={onCancelSession}
              />
            ))
            :
            (<NoSession />)
        }
      </div>

      {confirmationDialog.show && (
        <InfoModal
          isOpen
          title={confirmationDialog.title}
          status={confirmationDialog.status}
          onDismiss={() => {
            setConfirmationDialog({});
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div>
              {confirmationDialog.content}
            </div>
          </div>
        </InfoModal>
      )}
    </div>
  );
}
