import PostingForm from "app/components/PostingForm";
import React from "react";
import { TemplatePostingFormModel } from "./indexModel";
import PostModel from "app/models/PostModel";
import { useTranslation } from "react-i18next";
import usePlanFeature from "app/utils/services/plan_feature/user";

interface Props {
  onPost?: (post: PostModel) => void;
}

const TemplatePostingForm = ({ onPost }: Props) => {
  const { t } = useTranslation();
  const { user, canUploadMedia, onSubmit } = TemplatePostingFormModel({
    onPost,
  });
  const { canSchedulePosts } = usePlanFeature(user);

  return (
    <PostingForm
      textAreaPlaceholder={t(`Create post...`)}
      canUploadMedia={canUploadMedia()}
      canSchedule={canSchedulePosts}
      profilePicture={null}
      postButtonLabel="Post"
      postTextIsRequired
      hasLocationSelect={false}
      onPost={onSubmit}
      scheduledInActive
    />
  );
};

export default TemplatePostingForm;
