import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { AvailabilityModel } from "app/models/scheduling/AvailabilityModel";

const useAvailability = (startDate: DateTime, endDate: DateTime) => {
  const [availability, setAvailability] = useState([]);
  const [loading, setLoading] = useState(true);

  const start: string = startDate ? startDate.toISO() : null;
  const end: string = endDate ? endDate.toISO() : null;

  useEffect(() => {
    setLoading(true);

    if (!start || !end) {
      return;
    }

    const params = {
      start_date: start,
      end_date: end,
    };

    schedulingFetchGet(`/facilities/availability`, params)
      .then((availabilities) => {
        const models = availabilities.flatMap((availability: any) => {
          const r = AvailabilityModel.parseToLocalTime(availability);
          return r;
        });
        setAvailability(models);
        setLoading(false);

      }).catch(err => {
        console.error(err);
      });
  }, [start, end]);

  return { availability, loading };
};

export default useAvailability;
