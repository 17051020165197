import { FacilityModel } from 'app/models/scheduling/FacilityModel';
import { createContext } from 'react';

export enum RoleType {
  COACH = "coach",
  ADMIN = "admin",
  SCHEDULER = "scheduler",
  ATHLETE = "athlete",
}

export type UserContextType = {
  id?: string;
  kind?: string;
  role?: RoleType;
  facilityId?: string;
  facility?: FacilityModel;
  facilityMembershipId?: string;
  isAdmin?: boolean;
  features?: string[];
  behavesLikeAdmin?: boolean;
};

export const UserContext = createContext<UserContextType>({});
