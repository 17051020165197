import React, { useEffect, useMemo, useState } from "react";
import { cls } from "app/utils";
import styles from "./styles.module.scss";
import { PostItem } from "./PostItem";
import PostMediaModal from "../PostMedia/PostMediaView/PostMediaModal";

const DEFAULT_MAX_MEDIA_COUNT = 6;

interface Props {
  mediaData: any;
  // onClick: (id: number) => void;
}

export const PostMediaContainer = ({ mediaData }: Props) => {
  const [isShowMoreVisible, setIsShowMoreVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [mediasToShow, setMediasToShow] = useState(
    mediaData.slice(0, DEFAULT_MAX_MEDIA_COUNT),
  );
  const [selectedMedia, setSelectedMedia] = useState(null);

  const [mediaModal, setMediaModal] = useState(false);

  const modalHandler = (media: any) => {
    setMediaModal(true);
    setSelectedMedia(media);
  };

  const closeModal = () => {
    setMediaModal(false);
    setSelectedMedia(null);
  };

  const showMoreCount = useMemo(() => {
    const count = mediaData.length - DEFAULT_MAX_MEDIA_COUNT;
    return count > 0 ? count : 0;
  }, [mediaData.length]);

  useEffect(() => {
    if (mediaData.length > DEFAULT_MAX_MEDIA_COUNT) {
      setIsShowMoreVisible(true);
    } else {
      setIsShowMoreVisible(false);
    }
  }, [showMoreCount]);

  useEffect(() => {
    if (showMore) {
      setMediasToShow(mediaData);
    } else {
      setMediasToShow(mediaData.slice(0, DEFAULT_MAX_MEDIA_COUNT));
    }
  }, [showMore]);

  return (
    <div
      className={cls(styles.container, {
        [styles.layout1]: mediasToShow.length === 1,
        [styles.layout2]: mediasToShow.length === 2,
        [styles.layout3]: mediasToShow.length === 3,
        [styles.layout4]: mediasToShow.length === 4,
        [styles.layout5]: mediasToShow.length === 5,
        [styles.layout6]: mediasToShow.length > 5,
      })}
    >
      {mediasToShow.map((media: any, index: number) => {
        const isMediaLastItem = isLastItem(index, mediasToShow);

        return (
          <PostItem
            key={index}
            onClick={() => modalHandler(media)}
            mediaData={media}
            onClickLast={() => setShowMore(true)}
            isShowingMore={showMore}
            shouldShowMore={
              isMediaLastItem &&
              isShowMoreVisible &&
              !showMore &&
              +showMoreCount > 0
            }
            count={showMoreCount}
          />
        );
      })}

      <PostMediaModal
        onClose={closeModal}
        isOpen={mediaModal}
        mediaData={selectedMedia}
      />
    </div>
  );
};

const isLastItem = (index: number, array: any[]) => {
  return index === array.length - 1;
};
