import PublicWrapper from 'app/components/PublicWrapper';
import SessionConfirmation from 'app/routes/Sessions/SessionConfirmation/NewConfirmation';
import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { RootState } from 'typedefs';
import { useHistory } from 'react-router-dom';
import SimpleModal from '../SimpleModal';
import { Loading } from "app/components/Wrappers";
import { fetchSelf } from "app/actions/user";
import { useStoreActions } from "app/utils/hooks";

const Confirmation = () => {
  const [openAuthenticationModel, setOpenAuthenticationModel] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const { user } = useStoreActions({ user: fetchSelf });
  const external_id = useSelector((state: RootState): string => state.bookSession.coach.externalId);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(()=> {
    if (user.pending === true ) {
      setIsCheckingAuth(true);
    }
    if(user.self===null && user.pending===false) {
      setIsCheckingAuth(false);
      setOpenAuthenticationModel(true);
    } else if (user.self !== null && user.self.kind !== 'athlete') {
      setIsCheckingAuth(false);
      history.push('/');
    } else {
      setIsCheckingAuth(false);
      setOpenAuthenticationModel(false);
    }
  }
  , [user, openAuthenticationModel]);

  if (isCheckingAuth) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <PublicWrapper>
      <SimpleModal isOpen={openAuthenticationModel} title={t('Authentication required')} buttonText={t('Sign In / Sign Up')} onConfirm={() => (window as any).location = `/signin?return_url=/public/confirmation&coach_id=${external_id}`}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', color: '#8C8C8C' }}>
          <div>
            {t('Please sign in or sign up to complete your booking.')}
          </div>
        </div>
      </SimpleModal >
      <SessionConfirmation isPublic={true}/>
    </PublicWrapper>
  );
};
export default Confirmation;
