import React, { useState, useEffect, useContext } from "react";
import styles from "../../../routes/ViewProgram/styles.module.scss";
import { useSelector } from "react-redux";
import GroupCard from "../GroupCard";
import GroupDetails from "../GroupDetails";
import Calendar from "../../../../public/images/calendar-dark.svg";
import Location from "../../../../public/images/location-dark.svg";
import Dollar from "../../../../public/images/dollar-dark.svg";
import CalendarX from "../../../../public/images/calendar-x-red.svg";
import Checkmark from "../../../../public/images/green-checkmark.svg";
import Waitlist from "../../../../public/images/waitlist.svg";
import MessageIcon from "../../../../public/images/blue-mail.svg";
import TimeIcon from "../../../../public/images/time.svg";
import EditIcon from "../../../../public/images/edit-icon.svg";
import Navbar from "../NavBar";
import { hoursWithLables } from "../../../utils/timeUtils";
import RegisterStudentsPopup from "../RegisterStudentsPopup";
import Registrants from "../Registrants";
import TextAreaModal from "../TextAreaModal";
import {
  schedulingFetchDelete,
  schedulingFetchGet,
  schedulingFetchPost,
} from "app/utils/request/scheduling/fetch";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";
import ButtonsViewProgram from "../Buttons/ButtonsViewProgram";
import { useTranslation } from "react-i18next";
import SquaredColorOption from "../SquaredColorOption";
import { generateCurrencySymbol, getColorCode } from "app/routes/BuildProgram/Pricing/utils";
import SimpleModal from "app/components/Programs/SimpleModal";
import InformationModal from "../InformationModal";
import { truncate } from "lodash";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { RoleType, UserContext } from "app/contexts/UserContext";

interface DeleteResponse {
  firstOccurence?: number;
}

const ViewProgramCard = ({ program, programId, facilityId, setRefetch, isPublic = false }) => {
  const { t } = useTranslation();
  const [showRegistrans, setShowRegistrans] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [activeTab, setActiveTab] = useState("general");
  const [messageModal, setMessageModal] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showPublicRegisterPopup, setShowPublicRegisterPopup] =
    useState(false);
  const [showCancelEventPopup, setShowCancelEventPopup] = useState(false);
  const [eventItem, setEventItem] = useState(null);
  const [showDeleteRegistrantModal, setShowDeleteRegistrantModal] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [cancelReason, setCancelReason] = useState("");
  const userState = useSelector(
    (state: RootState): UserModel => state.user.data
  );
  const user = useContext(UserContext);
  const userType = user.role;
  const athleteView = userType === RoleType.ATHLETE || userType === RoleType.SCHEDULER;
  const coachView = !athleteView;
  const userId = userState?.id;
  const [participantStatus, setParticipantStatus] = useState("");
  const [noRegisteredParticipants, setNoRegisteredParticipants] = useState(0);
  const [message, setMessage] = useState("");
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [showGeneral, setShowGeneral] = useState(true);
  const [isViewOnly, _setIsViewOnly] = useState(false);
  const [reload, setReload] = useState(true);
  const [facilityIdOfCoach, setFacilityIdOfCoach] = useState(null);
  const width = window.innerWidth;
  const options = [
    { title: t("General"), isActive: activeTab === "general", code: "general" },
    { title: t("Details"), isActive: activeTab === "details", code: "details" },
  ];

  if(!isPublic || userState) {
    options.push({ title: t("Registrants"), isActive: activeTab === "registration", code: "registration" });
  }

  useEffect(() => {
    if (programId !== undefined && userId !== undefined) {
      schedulingFetchGet(`programs/${programId}/registrations`).then(
        (response) => {
          const completedParticipants = response?.data?.completed || [];
          const waitlistedParticipants = response?.data?.onWaitlist || [];

          setNoRegisteredParticipants(completedParticipants?.length);

          const isCompletedParticipant = completedParticipants.some(
            (participant) => participant?.id === userId?.toString()
          );

          const isWaitlistedParticipant = waitlistedParticipants.some(
            (participant) => participant?.id === userId?.toString()
          );
          if (isCompletedParticipant) {
            setParticipantStatus("registered");
          } else if (isWaitlistedParticipant) {
            setParticipantStatus("waitlisted");
          } else {
            setParticipantStatus("");
          }
        }
      );
    }
  }, [programId, userId, facilityId]);

  useEffect(() => {
    if(userType === 'coach') {
      schedulingFetchGet(`/coach_profiles/${userState?.id}`).then(
        (response) => {
          setFacilityIdOfCoach(response.facilityId);
        }
      );
    }
  }, [userType]);

  const formattedDate = (dateString) => {
    const date = DateTime.fromISO(dateString, {
      zone: program?.timeZone,
    }).toFormat("MMM d, yyyy");

    return date;
  };

  const handleRemoveParticipant = (item) => {
    setSelectedItem(item);
    setShowDeleteRegistrantModal(true);
  };

  const handleDelete = (item) => {
    setShowCancelEventPopup(true);
    setEventItem(item);
  };

  const handleRemoveEvent = () => {
    schedulingFetchDelete(`/programs/${eventItem}`, {
      reason: cancelReason,
    }).then((response: DeleteResponse) => {
      setShowCancelEventPopup(false);
      setEventItem(null);
      setCancelReason("");
      window.location.replace(`/programs/${response?.firstOccurence}/view`);
    });
  };

  const getImage = (type) => {
    switch (type) {
      case "waitlisted":
        return TimeIcon;
      case "completed":
        return Checkmark;
      case "full":
        return CalendarX;
      case "partial":
        return EditIcon;
      default:
        return CalendarX;
    }
  };

  const handleRegister = (message, reload = true) => {
    setModalInfo(message);
    setShowInformationModal(true);
    setReload(reload);
  };

  const handleSendMessage = () => {
    const params = {
      message,
    };
    if (message !== "") {
      schedulingFetchPost(`/programs/${programId}/send_message`, params);
      setMessageModal(false);
      setMessage("");
      toast.success(t("Message sent successfully."));
    } else {
      setMessageModal(false);
    }
  };

  const handleTabClick = (code: string) => {
    if (code === "registration") {
      setShowRegistrans(true);
      setShowGeneral(false);
      setShowDetails(false);
      setActiveTab("registration");
    } else if (code === "details") {
      setShowDetails(true);
      setShowGeneral(false);
      setShowRegistrans(false);
      setActiveTab("details");
    } else {
      setActiveTab("general");
      setShowRegistrans(false);
      setShowDetails(false);
      setShowGeneral(true);
    }
  };

  const groupCards = () => {
    return program?.events?.map((dateString, index) => {
      const startTimestr = dateString.localStartTime;
      const date = new Date(startTimestr);
      const id = dateString.eventableId;
      const startDateLocal = new Date(
        date.getTime() + date.getTimezoneOffset() * 60000
      );
      const startTime = program?.start?.slice(11, 16);
      const endTime = program?.end?.slice(11, 16);
      const startTimeProgram = hoursWithLables.find(
        (item) => item.value === startTime
      );

      const endTimeProgram = hoursWithLables.find(
        (item) => item.value === endTime
      );

      return (
        <GroupCard
          key={index}
          eventId={id}
          program={program}
          multipleDate={startDateLocal}
          handleDelete={handleDelete}
          startTimeProgram={startTimeProgram}
          endTimeProgram={endTimeProgram}
        />
      );
    });
  };

  const rightPanel = () => {
    return (
      <div className={coachView && styles.right_width}>
        <ButtonsViewProgram
          program={program}
          onMessageClick={() => setMessageModal(true)}
          onRegisterClick={() => setShowRegisterPopup(true)}
          onPublicRegisterClick={() => setShowPublicRegisterPopup(true)}
          participantStatus={participantStatus}
          noRegisteredParticipants={noRegisteredParticipants}
        />
        {athleteView && program?.status !== "completed" && (
          <>
            {(participantStatus === "registered" && (
              <div className={styles.status_container}>
                <img src={Checkmark} />
                <p className={styles.message}>{t("You are registered")}</p>
              </div>
            )) ||
              (participantStatus === "waitlisted" && (
                <div className={styles.status_container}>
                  <img src={Waitlist} />
                  <p className={styles.message}>{t("You are waitlisted")}</p>
                </div>
              ))}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.line_space_between}>
          <div className={styles.gap16}>
            <p className={styles.cardTitle}>
              {truncate(program?.title, { length: 75 })}
            </p>
            <p className={styles.cardSubtitle}>{program?.subtitle}</p>
          </div>
          {width > 450 && rightPanel()}
        </div>
        <div className={styles.line}>
          {program?.registrationStatus && (
            <div className={styles.registration_status}>
              <SquaredColorOption
                customContainer={styles.custom_registration}
                color={getColorCode(program?.registrationStatusCode)}
                text={program?.registrationStatus}
              />
            </div>
          )}
          <div className={styles.spotsBooked}>
            {program?.spotsBooked}/{program?.capacity} {t("Spots Booked")}
          </div>
          {width < 450 && rightPanel()}
        </div>

        <div className={styles.navbar}>
          <Navbar handleClick={handleTabClick} options={options} />
        </div>
        {showGeneral && (
          <>
            <div className={styles.cardInfo}>
              <div className={styles.cardInfoMain}>
                <span className={styles.icons}>
                  <img src={Calendar} />
                </span>
                <span className={styles.distanceTextWithIcon}>
                  {formattedDate(program?.events[0]?.startTime)}
                </span>
              </div>
              <div className={styles.cardInfoMain}>
                <span className={styles.icons}>
                  <img src={Location} />
                </span>
                <span className={styles.distanceTextWithIcon}>
                  {program?.location}
                </span>
              </div>
              <div className={styles.cardInfoMain}>
                <span className={styles.icons}>
                  <img src={Dollar} />
                </span>
                <span className={styles.distanceTextWithIcon}>
                  {generateCurrencySymbol(program?.facilityCurrency)}
                  {program?.price}
                </span>
              </div>
            </div>
            <div className={styles.description}>
              {program?.shortDescription}
            </div>
            <div id="group-card">{groupCards()}</div>
          </>
        )}
        {showRegistrans && (
          <div>
            <Registrants
              facilityId={facilityId}
              onClose={handleRegister}
              userType={userType}
              isViewOnly={isViewOnly}
              programId={programId}
              programStatus={program?.status}
              facilityIdOfCoach={facilityIdOfCoach}
            />
          </div>
        )}

        {showDetails && (
          <div>
            <GroupDetails program={program} />
          </div>
        )}

        <SimpleModal isOpen={showPublicRegisterPopup}
                     title={t('Authentication required')}
                     buttonText={t('Sign In / Sign Up')}
          onCancel={() => setShowPublicRegisterPopup(false)}
                     onConfirm={() => (window as any).location = `/signin?return_url=${isPublic ? '/public' : ''}/programs/${program.id}/view`}>
          <div className={styles.login_modal}>
            <div>
              {t('Please sign in or sign up to complete your program.')}
            </div>
          </div>
        </SimpleModal >

        {showInformationModal && (
          <InformationModal
            topImage={getImage(modalInfo?.type)}
            title={modalInfo?.message}
            subtitle={modalInfo?.details}
            handleAction={() => {
              setShowInformationModal(false);
              if (reload) {
                window.location.reload();
              } else {
                setRefetch(true);
              }
            }}
          />
        )}
        {showRegisterPopup && (
          <RegisterStudentsPopup
            participantStatus={participantStatus}
            showModal={setShowRegisterPopup}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            handleRemove={handleRemoveParticipant}
            showRemovePopup={showDeleteRegistrantModal}
            setShowRemovePopup={setShowDeleteRegistrantModal}
            programId={programId}
            facilityId={facilityId}
            setShowInformationModal={setShowInformationModal}
            setModalInfo={setModalInfo}
          />
        )}
        {messageModal && (
          <TextAreaModal
            topImage={MessageIcon}
            showCloseIcon
            title={t("Message")}
            subTitle={t("Send a message via email to the registered students.")}
            placeholder={t("Type here")}
            width="400px"
            customConfirmStyle={message !== "" ? styles.message_button : styles.disabled}
            confirmDisabled={message === ""}
            value={message}
            setValue={setMessage}
            stackedButtons
            confirmFirst
            confirmText={t("Send")}
            cancelText={t("Cancel")}
            setShowModal={setMessageModal}
            handleAction={handleSendMessage}
          />
        )}
        {showCancelEventPopup && (
          <TextAreaModal
            topImage={CalendarX}
            title={t("Are you sure you want to cancel this event?")}
            subTitle={t(
              "Send a message via email to the registered and waitlisted students."
            )}
            placeholder={t(
              "Ex: Today's clinic is canceled due to inclement weather."
            )}
            width="400px"
            stackedButtons
            showCloseIcon
            confirmFirst
            setValue={setCancelReason}
            value={cancelReason}
            confirmText={t("Yes, cancel it")}
            cancelText={t("No, keep it")}
            setShowModal={setShowCancelEventPopup}
            handleAction={handleRemoveEvent}
          />
        )}
      </div>
    </>
  );
};

export default ViewProgramCard;
