import UserModel from "app/models/UserModel";
import { useMemo } from "react";

interface returnType {
  canSchedulePosts: boolean;
  canPostMedia: boolean;
  canAddMedia: boolean;
  canCreateSegments: boolean;
  canCreateSpaces: boolean;
  canCreateTeams: boolean;
  canCreateTags: boolean;
  canCreateTemplates: boolean;
  hasCoachPlus: boolean;
}

function usePlanFeature(user: UserModel): returnType {
  const canSchedulePosts = useMemo((): boolean => {
    return user?.planPermissions?.includes("access_scheduled_posts") ?? false;
  }, [user]);

  const canPostMedia = useMemo((): boolean => {
    return user?.planPermissions?.includes("post_media") ?? false;
  }, [user]);

  const canAddMedia = useMemo((): boolean => {
    return user?.planPermissions?.includes("upload_to_library") ?? false;
  }, [user]);

  const canCreateSegments = useMemo((): boolean => {
    return user?.planPermissions?.includes("create_segments") ?? false;
  }, [user]);

  const canCreateSpaces = useMemo((): boolean => {
    return user?.planPermissions?.includes("create_spaces") ?? false;
  }, [user]);

  const canCreateTeams = useMemo((): boolean => {
    return user?.planPermissions?.includes("create_teams") ?? false;
  }, [user]);

  const canCreateTags = useMemo((): boolean => {
    return user?.planPermissions?.includes("create_tags") ?? false;
  }, [user]);

  const canCreateTemplates = useMemo((): boolean => {
    return user?.planPermissions?.includes("create_templates") ?? false;
  }, [user]);

  const hasCoachPlus = useMemo((): boolean => {
    return (
      user?.subscription?.status === "active" ||
      user?.subscription?.status === "trial"
    );
  }, [user]);

  return {
    canSchedulePosts,
    canPostMedia,
    canAddMedia,
    canCreateSegments,
    canCreateSpaces,
    canCreateTeams,
    canCreateTags,
    canCreateTemplates,
    hasCoachPlus,
  };
}

export default usePlanFeature;
