import styles from "./styles.module.scss";
import React from "react";
import playIcon from "../../../../../../../public/images/play-overlay.png";
import { v4 } from "uuid";

interface Props {
  mediaData: any;
  onClick: () => void;
  shouldShowMore?: boolean;
  showMoreOverlay?: React.ReactElement;
}

const VideoPostItem = ({
  mediaData,
  onClick,
  shouldShowMore,
  showMoreOverlay,
}: Props) => {
  return (
    <div
      key={v4()}
      className={styles.video_container}
      onClick={!shouldShowMore ? onClick : undefined}
    >
      {shouldShowMore && showMoreOverlay}
      {mediaData?.processed && shouldShowMore && (
        <img
          className={styles.thumbnail}
          src={mediaData?.thumbnails?.default}
          alt="media"
        />
      )}

      {mediaData?.processed && !shouldShowMore ? (
        <>
          <span className={styles.media_overlay}>
            <img alt="play" src={playIcon} />
          </span>
          <img
            className={styles.thumbnail}
            src={mediaData?.thumbnails?.default}
            alt="media"
          />
        </>
      ) : (
        <div className="media-sprite media-sprite-md media-sprite-vid" />
      )}
    </div>
  );
};

export default VideoPostItem;
