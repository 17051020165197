import React from "react";
import styles from "./styles.module.scss";
import UserAvatar from "../UserAvatar";
import SidebarMenuList from "./SidebarMenuList";
import SidebarDropdown from "./SidebarDropdown";
import edufii_logo from "app/images/Logo.svg";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useHistory } from "react-router-dom";
import SubscriptionBadge from "../SubscriptionBadge";

const Sidebar = () => {
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual,
  );
  const history = useHistory();

  const onLogoClick = () => {
    history.push("/");
  };

  return (
    <div className={styles.sidebar_menu_container}>
      <div className={styles.site_sidebar}>
        <div className={styles.logo} onClick={onLogoClick}>
          <img src={edufii_logo} alt="CoachNow" />
        </div>
        <div className={styles.profile_info_container}>
          <div className={styles.profile_info_wrapper}>
            {user?.avatar && (
              <UserAvatar
                className={styles.avatar}
                srcName={user.avatar as string}
                userName={user.displayName}
              />
            )}
            <div className={styles.name_membership_container}>
              <div className={styles.display_name}>{user?.displayName}</div>
              <div className={styles.plan_type}>
                <SubscriptionBadge />
              </div>
            </div>
          </div>
          <SidebarDropdown user={user} />
        </div>
        <SidebarMenuList user={user} />
      </div>
    </div>
  );
};

export default Sidebar;
