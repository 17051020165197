import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import styles from "./styles.module.scss";
import Navbar from "app/routes/Facility/Navbar";
import PagePanel from "app/components/PagePanel";
import FacilityItem from "app/routes/Facility/FacilityItem";
import {fetchGet, fetchPut} from "app/utils/request";
import {toast} from "react-toastify";

const Facility = () => {
  const { t } = useTranslation();
  const [facilities, setFacilities] = React.useState([]);

  useEffect(() => {
    fetchGet("/facilities", {}, {version: 1})
      .then((response) => {
      setFacilities(response);
      }).catch((error) => {
        console.error(error);
      });
  }, []);

  const handleEditFacility = (facility) => {
    fetchPut(`/facilities/${facility.id}`, { facility }, {version: 1})
      .then(() => {
        toast.success("Facility updated successfully");
        setFacilities((prevFacilities) =>
          prevFacilities.map((f) =>
            f.id === facility.id ? facility : f
          )
        );
      }).catch((error) => {
        toast.error("Failed to update facility");
        console.error(error);
      });
  };

  return (
    <div>
      <div className={styles.title}>
        <h1>{t("My Facility")}</h1>
      </div>
      <div>
        <Navbar location={"general"}/>
        <PagePanel title={t("General")} titleStyles={styles.header}>
          <div className={styles.list_of_facilities}>
            {facilities.map((facility) => (
              <FacilityItem key={facility.id} facility={facility} onEdit={handleEditFacility} />
            ))}
          </div>
        </PagePanel>
      </div>
    </div>
  );
};

export default Facility;
