import React from 'react';
import {useForm} from "react-hook-form";
import styles from "../styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import CloseIcon from "../../../../public/images/close.svg";
import {useTranslation} from "react-i18next";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isAdmin: boolean;
}

interface EditFacilityUser {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  onEdit: (user: User) => void;
}

const EditFacilityUser = ({ isOpen, onClose, user, onEdit }: EditFacilityUser) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<User>({ defaultValues: user});
  const { t } = useTranslation();

  const onSubmit = (data: User) => {
    const formatedData = {
      id: user.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: user.email,
      role: user.role,
      isAdmin: user.isAdmin
    };
    onEdit(formatedData);
    onClose();
    reset({
      id: "",
      lastName: "",
      firstName: ""
    });
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.modal_header}>
        <h2>{t('Edit User')}</h2>
        <img className={styles.modal_status_icon} src={CloseIcon} alt="info" onClick={onClose}/>
      </div>
      <div className={styles.modal_content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_row}>
            <label htmlFor="first_name">{t('First Name')}</label>
            <input
              id="first_name"
              {...register('firstName', {required: true})}
            />
            {errors.firstName && <span>{t("This field is required")}</span>}
          </div>
          <div className={styles.form_row}>
            <label htmlFor="last_name">{t('Last Name')}</label>
            <input
              id="last_name"
              {...register('lastName', {required: true})}
            />
            {errors.lastName && <span>{t("This field is required")}</span>}
          </div>
          <div className={styles.form_row}>
            <label htmlFor="email">{t('Email')}</label>
            <input
              id="email"
              {...register('email', {required: true})}
              readOnly={true}
            />
          </div>
          <div className={styles.buttonGroup}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={onClose}
            >
              {t("Cancel")}
            </button>
            <button type="submit" className={styles.confirmButton}>
              {t("Save")}
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
};

export default EditFacilityUser;
