import React, { FC } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import AccountWrap from "..";
import Button from "app/components/Button/ButtonVariant";
import SubscriptionCancelModal from "app/components/subscription/Modals/SubscriptionCancelModal";
import { useSubscriptionModel } from "./indexModel";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SubscriptionModel from "app/models/SubscriptionModel";

const Subscriptions: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    subscriptions,
    pending,
    cancelModal,
    toggleCancelModal,
    isExpired,
    canCancel,
    canReactivate,
    isTrial,
    formatDate,
  } = useSubscriptionModel();

  const navigateToUpgrade = (planCode?: string) => {
    const path = planCode
      ? `/upgrade?plan=${encodeURIComponent(planCode)}`
      : "/upgrade";
    history.push(path);
  };

  const SubscriptionInfo: FC<{ subscription: SubscriptionModel }> = ({
    subscription,
  }) => {
    if (subscription.isAutoRenewing && subscription.expiresAt) {
      return (
        <p className={cls(styles.plan_info)}>
          {t("Renews on")}: {formatDate(subscription.expiresAt)}
        </p>
      );
    }
    if (!subscription.isAutoRenewing && subscription.expiresAt) {
      return (
        <p
          className={cls(
            styles.plan_info,
            isExpired(subscription) ? "text-danger" : "",
          )}
        >
          {isExpired(subscription) ? t("Expired on") : t("Expires on")}:{" "}
          {formatDate(subscription.expiresAt)}
        </p>
      );
    }
    return null;
  };

  const SubscriptionActions: FC<{ subscription: SubscriptionModel }> = ({
    subscription,
  }) => {
    if (canCancel(subscription)) {
      return (
        <Button
          title={t("Cancel")}
          buttonType="danger"
          onClickFunc={toggleCancelModal}
        />
      );
    }
    if (isTrial(subscription) || canReactivate(subscription)) {
      return (
        <Button
          title={isTrial(subscription) ? t("Upgrade") : t("Reactivate")}
          buttonType="primary"
          onClickFunc={() => navigateToUpgrade(subscription.planCode)}
        />
      );
    }
    return null;
  };

  const renderSubscriptions = () => {
    if (pending) {
      return (
        <div className={styles.loading_wrapper}>
          <b>{t("Please wait...")}</b>
        </div>
      );
    }

    if (!subscriptions || subscriptions.length === 0) {
      return (
        <div className={styles.plan_container}>
          <div className={styles.plan_wrapper}>
            <h4 className={styles.plan_title}>{t("Coachnow+")}</h4>
          </div>
          <div className={styles.start_trial_btns}>
            <Button
              title={t("Start Free Trial")}
              buttonType="success"
              onClickFunc={() => navigateToUpgrade()}
            />
            <Button
              title={t("Upgrade")}
              buttonType="primary"
              onClickFunc={() => {}}
            />
          </div>
        </div>
      );
    }

    return subscriptions.map((subscription: SubscriptionModel) => (
      <div key={subscription._id} className={styles.plan_container}>
        <div className={styles.plan_wrapper}>
          <h4 className={styles.plan_title}>{subscription.planName}</h4>
          <SubscriptionInfo subscription={subscription} />
        </div>
        <SubscriptionActions subscription={subscription} />
        {cancelModal && (
          <SubscriptionCancelModal
            subscription={subscription}
            isOpen={cancelModal}
            onClose={toggleCancelModal}
          />
        )}
      </div>
    ));
  };

  return (
    <AccountWrap>
      <div className={cls(styles.container, "panel")}>
        <h1 className={styles.title}>{t("Subscriptions")}</h1>
        <div className={styles.subscriptions_list_container}>
          {renderSubscriptions()}
        </div>
      </div>
    </AccountWrap>
  );
};

export default Subscriptions;
