import React, { useRef, useState, useEffect } from "react";
import {
  computePosition,
  autoUpdate,
  shift,
  flip,
  hide,
} from "@floating-ui/dom";

import { EventModel } from "app/models/EventModel";

import styles from "./styles.module.scss";
import ScheduleBlocksInfo from "./ScheduleBlocksInfo";
import ProgramInfo from "./ProgramInfo";

type PropsDef = {
  event?: EventModel;
  coords?: {
    x: number;
    y: number;
  };
  referenceElement: any;
  onDelete: () => void;
  onEdit: () => void;
  timeZone?: string;
};

export default function Event(props: PropsDef) {
  const { event, referenceElement, onDelete, onEdit, timeZone } = props;
  const currentElement = useRef(null);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (!referenceElement || !currentElement.current) {
      // @ts-ignore: next-line
      return;
    }

    const cleanup = autoUpdate(referenceElement, currentElement.current, () => {
      computePosition(referenceElement, currentElement.current, {
        middleware: [shift(), flip(), hide()],
      }).then(({ x, y, middlewareData }) => {
        setHidden(middlewareData.hide.referenceHidden);
        setPosition({ left: x, top: y });
      });
    });

    return () => {
      cleanup();
    };
  }, [event?.id, referenceElement]);

  if (!props.event?.id) {
    return null;
  }

  return (
    <div
      className={styles.popup}
      ref={currentElement}
      style={{
        left: position.left,
        top: position.top,
        visibility: hidden ? "hidden" : "visible",
        zIndex: 2,
      }}
    >
      {event.type === "program" ? (
        <ProgramInfo event={event} timeZone={timeZone} />
      ) : (
        <ScheduleBlocksInfo event={event} onDelete={onDelete} onEdit={onEdit} />
      )}
    </div>
  );
}
