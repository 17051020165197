import React from 'react';
import { Link } from 'react-router-dom';
import styles from "./styles.module.scss";
import { useTranslation } from 'react-i18next';

interface NavbarProps {
  location: string;
}

const Navbar = ({ location }: NavbarProps) => {
  const { t } = useTranslation();

  return (
    <nav className={styles.navbar}>
      <ul className={styles.navList}>
        <Link to={"/manage_facility"} className={`${styles.navItem} ${location === 'general' ? styles.active : ''}`}>
          {t("General")}
        </Link>
        <Link to={"/manage_facility/users"} className={`${styles.navItem} ${location === 'users' ? styles.active : ''}`}>
          {t("Users")}
        </Link>
      </ul>
      <div className={styles.horizontalLine} />
    </nav>
  );
};

export default Navbar;
