/**
 * @module Components.Routes
 *
 */
import React, { useState, useRef, useEffect, FormEvent } from "react";
import {
  FormattedMessage,
  MessageDescriptor,
  defineMessages,
} from "react-intl";
import { resolve } from "rsvp";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useLoc, usePending, useError, useUrlSearch } from "app/utils/hooks";
import { routeNative } from "app/utils/router";
import { AuthService, PhoneVerificationService } from "app/services";
import { VerificationModel } from "app/models";
import { Wrapper } from "app/components/Onboarding";
import { TabView, TabContent } from "app/components/TabView";
import FormItem from "app/components/inputs/FormItem";
import ResendButton from "app/components/PhoneVerification/ResendButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Styles from "./styles.module.scss";
import GGLogo from "../../../public/images/gg_logo.svg";
import { IUserPhone } from "src/interfaces/UserTypes";
import { Trans, useTranslation } from "react-i18next";

type State = {
  verification: Partial<VerificationModel> | null;
  defaultTabIndex: number;
  phone: string;
  phoneCountry: string;
  phoneNormalized: string;
};

const language = navigator.language || navigator.userLanguage;
const initState: State = {
  verification: null,
  defaultTabIndex: language.match("zh") == null ? 0 : 1,
  phone: null,
  phoneCountry: null,
  phoneNormalized: null,
};

type ErrorState = {
  loginError?: MessageDescriptor | string | null;
  facebookError?: MessageDescriptor | string | null;
};

const initError: ErrorState = {
  loginError: null,
  facebookError: null,
};

/**
 * @class Signin
 *
 */
export default function SignIn() {
  const { t } = useTranslation();
  const [state, setState] = useState(() => ({ ...initState }));
  const [pending, startPending, stopPending] = usePending();
  const [errorState, addErrors, clearErrors] = useError(initError);
  const [codeFailed, setCodeFailed] = useState<boolean>(false);
  const { formatMessage } = useLoc();
  const [searchParams, search] = useUrlSearch();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const codeRef = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const LOCALES = defineMessages({
    main_title: { id: "app.title.main", defaultMessage: t("Sign in") },
    golf_genius: {
      id: "app.button.golf-genius",
      defaultMessage: t("Sign In with Golf Genius"),
    },
    signin: { id: "app.button.signin", defaultMessage: t("Sign In") },
    signup: { id: "app.button.signup", defaultMessage: t("Sign Up") },
    email: { id: "app.form-label.email", defaultMessage: t("E-mail") },
    phone: { id: "app.form-label.phone", defaultMessage: t("Phone") },
    code: { id: "app.form-label.code", defaultMessage: t("Verification Code") },
    password: { id: "app.form-label.password", defaultMessage: t("Password") },
    remember: {
      id: "app.form-label.remember-me",
      defaultMessage: t("Remember Me"),
    },
    forgot: {
      id: "app.form-label.forgot-password",
      defaultMessage: t("Forgot password?"),
    },
    or_opt: {
      id: "signin.form-label.or-option",
      defaultMessage: t("or sign in with"),
    },
    next: { id: "app.button.next", defaultMessage: t("Next") },
    back: { id: "app.button.back", defaultMessage: t("Back") },
    bad_login: {
      id: "error.signin.bad_login",
      defaultMessage: t(
        "The email address or password you entered is incorrect."
      ),
    },
    standard_invalid_grant: {
      id: "error.signin.invalid_grant",
      defaultMessage: t(
        "The email address or password you entered is incorrect."
      ),
    },
    facebook_grant_rejected: {
      id: "error.signin.facebook_grant_rejected",
      defaultMessage: t(
        "No existing CoachNow account found. Please Sign Up with Facebook to get started."
      ),
    },
    unknown: {
      id: "error.signin.unknown",
      defaultMessage: t("An unknown error occurred. Please try again."),
    },
    standard_unknown: {
      id: "error.signin.unknown",
      defaultMessage: t("An unknown error occurred. Please try again."),
    },
    facebook_unknown: {
      id: "error.signin.unknown",
      defaultMessage: t("An unknown error occurred. Please try again."),
    },
    code_unknown: {
      id: "error.signin-code.unknown",
      defaultMessage: t("Signin as failed. Error Unknown."),
    },
    code_expired: {
      id: "error.signin-code.expired",
      defaultMessage: t("Signin as failed. Code Expired."),
    },
    code_invalid_grant: {
      id: "error.signin-code.invalid_grant",
      defaultMessage: t("Signin as failed. The code is invalid."),
    },
    phone_verification_unprocessable_entity: {
      id: "error.signin-phone-verification.unprocessable_entity",
      defaultMessage: t("Signin failed. Please provide a valid phone number"),
    },
    phone_verification_invalid_code: {
      id: "error.signin-verification-token.invalid_verification",
      defaultMessage: t("Signin failed. Verification code is invalid."),
    },
    phone_verification_unknown: {
      id: "error.signin-phone-verification.unknown",
      defaultMessage: t("Signin failed. Error unknown"),
    },
    verification_token_unknown: {
      id: "error.signin-verification-token.unknown",
      defaultMessage: t("Signin failed. Error unknown."),
    },
    verification_token_invalid_grant: {
      id: "error.signin-verification-token.invalid_grant",
      defaultMessage: t("Signin failed. User not found."),
    },
    verification_token_invalid_verification: {
      id: "error.signin-verification-token.invalid_verification",
      defaultMessage: t("Signin failed. Verification code is invalid."),
    },
  });

  // const handleGolfGeniusSignUp = async () => {
  //   startPending();
  //   const authAny = (AuthService as any).signUpWithGolfGenius(
  //     searchParams.code
  //   );

  //   authAny
  //     .then((user: UserModel) => {

  //       accountCreated({ user });
  //       routeNative(searchParams.return_url || '/signup', { reload: true })
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(stopPending);
  // };

  useEffect(() => {
    const { code, return_url } = searchParams;

    if (!code) {
      return;
    }

    const isOAuthPath = location.pathname === "/signin/oauth";

    if (isOAuthPath) {
      (AuthService as any)
        .authenticateWithGolfGenius(code)
        .then(() => routeNative(return_url || "/", { reload: true }))
        .catch(
          (err: "code_unknown" | "code_invalid_grant" | "code_expired") => {
            addErrors({ loginError: LOCALES[err] });
            setCodeFailed(true);
          }
        );
    } else {
      (AuthService as any)
        .authenticateWithCode(code)
        .then(() => routeNative(return_url || "/", { reload: true }))
        .catch(
          (err: "code_unknown" | "code_invalid_grant" | "code_expired") => {
            addErrors({ loginError: LOCALES[err] });
            setCodeFailed(true);
          }
        );
    }
  }, [searchParams.code]);

  if (searchParams.code && !codeFailed) {
    return <Wrapper loading={true} />;
  } else if (!codeFailed && (AuthService as any).isAuthenticated) {
    if (searchParams.return_url != null) {
      return <Redirect to={searchParams.return_url} />;
    } else if (searchParams.returnUrl != null) {
      return <Redirect to={searchParams.returnUrl} />;
    }
    return <Redirect to="/" />;
  }

  let signupLink = "/signup";
  if (search.length) {
    signupLink = `${signupLink}${search}`;
  }

  const setError = (type: string, err: keyof typeof LOCALES) => {
    addErrors({ [type]: LOCALES[err] || LOCALES["unknown"] });
  };

  const handlePhoneVerification = (e: FormEvent<HTMLFormElement>) => {
    const codeEl = codeRef.current;

    e.preventDefault();
    clearErrors();

    if (state.phone == null || state.phoneCountry == null) {
      addErrors({
        loginError: LOCALES.phone_verification_unprocessable_entity,
      });
      return resolve();
    }

    startPending();
    return (PhoneVerificationService as any)
      .verify(state.phone, state.phoneCountry)
      .catch(
        (
          err:
            | "phone_verification_unprocessable_entity"
            | "phone_verification_unknown"
        ) => {
          addErrors({ loginError: LOCALES[err] });
          setCodeFailed(true);
          stopPending();
        }
      )
      .then((verification: VerificationModel) => {
        stopPending();
        setState({ ...state, verification });
        codeEl.focus();
      });
  };

  const handleCodeConfirmation = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors();

    const codeEl = codeRef.current;
    const verification = state.verification;

    if (codeEl == null || verification == null) {
      addErrors({ loginError: LOCALES.phone_verification_invalid_code });
      return resolve();
    }

    const code = codeEl.value.trim();

    startPending();
    return (PhoneVerificationService as any)
      .confirm(verification.id, code)
      .catch(() => {
        addErrors({ loginError: LOCALES.phone_verification_invalid_code });
        setCodeFailed(true);
        stopPending();
      })
      .then(() => {
        (AuthService as any)
          .authenticateWithVerificationToken(verification.token)
          .then(() =>
            routeNative(searchParams.return_url || "/", { reload: true })
          )
          .catch(
            (
              err:
                | "verification_token_unknown"
                | "verification_token_invalid_grant"
                | "verification_token_invalid_verification"
            ) => {
              addErrors({ loginError: LOCALES[err] });
              setCodeFailed(true);
              stopPending();
            }
          );
      });
  };

  const handleSignin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const usernameEl = usernameRef.current;
    const passwordEl = passwordRef.current;

    if (usernameEl == null || passwordEl == null) {
      return Promise.resolve();
    }

    clearErrors();
    const username = usernameEl.value.trim();
    const password = passwordEl.value.trim();
    if (!(username && username.length && password && password.length)) {
      addErrors({ loginError: LOCALES.bad_login });
      return resolve();
    }
    startPending();
    return (AuthService as any)
      .authenticate(username, password)
      .then(() => routeNative(searchParams.return_url || "/", { reload: true }))
      .catch(
        (err: keyof typeof LOCALES) => (
          setError("loginError", err), stopPending()
        )
      );
  };

  const handlePhoneInputChange = (
    value: string,
    data: IUserPhone,
    _event: React.FormEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const phoneNormalized = formattedValue;
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();

    setState({ ...state, phone, phoneCountry, phoneNormalized });
  };

  const handleBackClick = () => {
    const defaultTabIndex = 1;

    clearErrors();
    setState({ ...initState, defaultTabIndex });
  };

  const handleGolfGeniusSignin = () => {
    window.location.replace(
      "https://www.golfgenius.com/oauth/authorize?client_id=w4QZoQKUVw432h-4feQET5x94KH3XJXVRwWVGlBF-T0&redirect_uri=https://app.coachnow.io/signin/oauth&response_type=code&scope=openid"
    );
  };

  // const handleFacebookSignin = () => {
  //   startPending();
  //   clearErrors();
  //   return (AuthService as any).authenticateWithFacebook()
  //     .catch((err: keyof typeof LOCALES) => (setError('facebookError', err), stopPending()))
  //     .then(() => stopPending());
  // };

  return (
    <Wrapper>
      <div className={Styles.mainTitle}>
        {formatMessage(LOCALES.main_title)}
      </div>
      <div className={Styles.mainWrapper}>
        <TabView
          defaultIndex={state.defaultTabIndex}
          styles={Styles}
          onSelect={clearErrors}
        >
          <TabContent
            key={0}
            tabTitle={formatMessage(LOCALES.email)}
            icon={<i className="ico ico-squared-mail bold"></i>}
          >
            <form noValidate onSubmit={(e) => handleSignin(e)} target="_self">
              <FormItem>
                <input
                  autoFocus={true}
                  className="username"
                  name="username"
                  ref={usernameRef}
                  placeholder={formatMessage(LOCALES.email)}
                  type="text"
                />
              </FormItem>

              <FormItem>
                <input
                  className="password"
                  name="password"
                  ref={passwordRef}
                  placeholder={formatMessage(LOCALES.password)}
                  type="password"
                />
              </FormItem>
              <div className={Styles.formSpace}></div>
              <FormItem error={errorState.loginError}>
                <button
                  className="btn btn-primary full signin mt-0"
                  disabled={pending.status}
                >
                  <FormattedMessage {...LOCALES.signin} />
                </button>
              </FormItem>

              <div className={Styles.separator}>
                <span>OR</span>
              </div>

              <button
                className={
                  Styles.GGButton + " btn btn-primary full signin mt-0"
                }
                disabled={pending.status}
                type="button"
                onClick={handleGolfGeniusSignin}
              >
                <img src={GGLogo} alt="gg_logo" />
                <FormattedMessage {...LOCALES.golf_genius} />
              </button>
            </form>
          </TabContent>

          <TabContent
            key={1}
            tabTitle={formatMessage(LOCALES.phone)}
            icon={<i className="ico ico-new-phone bold"></i>}
          >
            <form
              noValidate
              className={state.verification ? Styles.hidden : ""}
              onSubmit={(e) => handlePhoneVerification(e)}
              target="_self"
            >
              <FormItem>
                <PhoneInput
                  value={state.phoneNormalized}
                  country={state.phoneCountry || "us"}
                  countryCodeEditable={false}
                  onChange={handlePhoneInputChange}
                  inputStyle={{ width: "100%", height: "42px" }}
                  inputProps={{ autoFocus: true }}
                />
              </FormItem>

              <FormItem error={errorState.loginError}>
                <button
                  className="btn btn-primary full signin mt-0"
                  disabled={pending.status}
                >
                  <FormattedMessage {...LOCALES.next} />
                </button>
              </FormItem>
            </form>

            <div
              className={
                !state.verification
                  ? Styles.hidden
                  : Styles.confirmationSentText
              }
            >
              <Trans
                i18nKey="confirmation_code_sent"
                components={{ strong: <strong />, br: <br /> }}
                values={{ phoneNumber: state.phoneNormalized }}
              />
            </div>

            <form
              noValidate
              className={!state.verification ? Styles.hidden : ""}
              onSubmit={(e) => handleCodeConfirmation(e)}
              target="_self"
            >
              <FormItem>
                <input
                  className="code"
                  name="code"
                  ref={codeRef}
                  placeholder={formatMessage(LOCALES.code)}
                  type="text"
                />
              </FormItem>

              {state.verification ? (
                <ResendButton
                  phone={state.phone}
                  phoneCountry={state.phoneCountry}
                />
              ) : (
                ""
              )}

              <FormItem error={errorState.loginError}>
                <button
                  className="btn btn-primary full signin mt-0"
                  disabled={pending.status || !state.verification}
                >
                  <FormattedMessage {...LOCALES.next} />
                </button>
              </FormItem>
            </form>

            <div>
              <button
                className={
                  !state.verification
                    ? Styles.hidden
                    : "btn btn-secondary full signin"
                }
                onClick={handleBackClick}
              >
                <FormattedMessage {...LOCALES.back} />
              </button>
            </div>
          </TabContent>
        </TabView>
        <FormItem>
          <Link className="btn btn-link full" to="/reset">
            <FormattedMessage {...LOCALES.forgot} />
          </Link>
          <Link className="btn btn-link full" to={signupLink}>
            <FormattedMessage {...LOCALES.signup} />
          </Link>
        </FormItem>
      </div>
    </Wrapper>
  );
}
