import React, { useContext } from "react";
import styles from "./styles.module.scss";
import ButtonViewProgram from "../ButtonViewProgram";
import StudentViewButtons from "../StudentViewButtons";
import CoachViewButtons from "../CoachViewButtons";
import { RoleType, UserContext } from "app/contexts/UserContext";

const ButtonsViewProgram = ({
  program,
  onRegisterClick,
  onPublicRegisterClick,
  participantStatus,
  noRegisteredParticipants,
  onMessageClick,
}) => {
  const user = useContext(UserContext);

  if (user.role === RoleType.ATHLETE || user.role === RoleType.SCHEDULER) {
    return (
      <StudentViewButtons
        program={program}
        onRegisterClick={onRegisterClick}
        participantStatus={participantStatus}
      />
    );
  } else if (!user.role) {
    return (
      <div className={styles.buttonsContainer}>
        <ButtonViewProgram
          colorStyle={styles.save_btn}
          text={"Register"}
          handleClick={onPublicRegisterClick}
        />
      </div>
    );
  } else {
    return (
      <CoachViewButtons
        onMessageClick={onMessageClick}
        noRegisteredParticipants={noRegisteredParticipants}
      />
    );
  }
};

export default ButtonsViewProgram;
