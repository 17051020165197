/**
 * @module Utils
 *
 */
import { UserModel, UserPermissionTypes } from "app/models/UserModel";

export function hasCoachPro(user: UserModel): boolean {
  return user.plan?.planCode?.startsWith("coachpro+");
}

export function hasAcademyPlan(user: UserModel): boolean {
  return user.plan?.planCode?.startsWith("academy");
}

export function hasAnalyzePlan(user: UserModel): boolean {
  return user.plan?.planCode?.startsWith("analyze");
}

export function hasCoachPlus(user: UserModel): boolean {
  return (
    user.subscription.status === "active" ||
    user.subscription.status === "trial"
  );
}

export function hasCoachPlusTrial(user: UserModel): boolean {
  return user.subscription.status === "trial";
}

export function hasExpiredCoachPlus(user: UserModel): boolean {
  return (
    user.subscription.status === "expired" ||
    user.subscription.status === "trial_expired"
  );
}

export function hasExpiredSubscription(user: UserModel): boolean {
  return (
    !!user &&
    !user.hasCoachPlusSubscription &&
    !user.hasTrialSubscription &&
    user.subscription?.status === "expired"
  );
}

export function hasExpiredCoachPlusSubscription(user: UserModel): boolean {
  return user.subscription.status === "expired";
}

export function hasExpiredCoachPlusTrial(user: UserModel): boolean {
  return user.subscription.status === "trial_expired";
}

export function hasTrialSubscription(user: UserModel): boolean {
  return user.hasTrialSubscription;
}

export function hasFreeSlots(user: UserModel): boolean {
  return user.plan.slotsCount < user.plan.spacesLimit;
}

export function permissionFor(
  user: UserModel,
  permission: UserPermissionTypes,
) {
  return user.planPermissions.indexOf(permission) !== -1;
}
