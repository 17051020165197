/**
 * @module Model.Availability
 *
 */
import { Model } from "typedefs";
import { DateTime, Interval } from "luxon";

export class AvailabilityModel implements Model {
  date: DateTime;
  daysOfWeek: number[];
  startTime: string;
  endTime: string;
  coachProfileId?: string;
  resourceId?: string;
  timeZone?: string;
  localStartTime?: string;
  localEndTime?: string;

  constructor(availability: any) {
    this.date = DateTime.fromISO(availability.date);
    this.daysOfWeek = [availability.dayOfWeek];
    this.startTime = availability.startTime;
    this.endTime = availability.endTime;
    this.coachProfileId = availability.coachProfileId;
    this.resourceId = availability.coachProfileId;
    this.timeZone = availability.timeZone;
    this.localStartTime = availability.localStartTime;
    this.localEndTime = availability.localEndTime;
  }

  public static getIntervals(avs: AvailabilityModel[]): Interval[]  {
    return avs.map((av: AvailabilityModel): Interval => {
      return Interval.fromDateTimes(
        DateTime.fromISO(av.localStartTime).setZone(av.timeZone),
        DateTime.fromISO(av.localEndTime).setZone(av.timeZone),
      );
    });
  }

  public static parseToLocalTime(payload: any): AvailabilityModel[] {
    const { localStartTime, localEndTime, available } = payload;

    if (!available) {
      return [new AvailabilityModel({...payload, startTime: '08:00', endTime: '08:00'})];
    }

    const start = DateTime.fromISO(localStartTime);
    const end = DateTime.fromISO(localEndTime);

    if (start.day === end.day) {
      return [
        new AvailabilityModel({
          ...payload,
          startTime: start.toFormat('HH:mm'),
          endTime: end.toFormat('HH:mm'),
        })
      ];
    }

    return [
      new AvailabilityModel({
        ...payload,
        startTime: start.toFormat('HH:mm'),
        endTime: start.endOf('day').toFormat('HH:mm'),
        dayOfWeek: start.toJSDate().getDay(),
      }),
      new AvailabilityModel({
        ...payload,
        startTime: end.startOf('day').toFormat('HH:mm'),
        endTime: end.toFormat('HH:mm'),
        dayOfWeek: end.toJSDate().getDay(),
      }),
    ];
  }
}
