import React from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { deselectEvent } from "app/actions/events";
import { useTranslation } from "react-i18next";
import SquaredColorOption from "app/components/Programs/SquaredColorOption";
import { getColorCode } from "app/routes/BuildProgram/Pricing/utils";
import Eye from "app/images/eye.svg";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";


const ProgramInfo = ({ event, timeZone}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const startTimeString = DateTime.fromISO(event.start)
    .setZone(timeZone)
    .toLocaleString(DateTime.TIME_SIMPLE);
  const endTimeString = DateTime.fromISO(event.end)
    .setZone(timeZone)
    .toLocaleString(DateTime.TIME_SIMPLE);
  const timeZoneShortString = DateTime.fromISO(event.end)
    .setZone(timeZone)
    .toFormat("ZZZZ");
  const intervalTimeString = `${startTimeString} - ${endTimeString} ${timeZoneShortString}`;
  return (
    <>
      <div className={styles.header}>
        <div className={styles.event_title}>
          <p>{event.title}</p>
          <div className={styles.repeat}>{event.subTitle}</div>
        </div>
        <div className={styles.header_actions}>
          {!event.externalCalendarId && (
            <Link to={`/programs/${event.id}/view`}>
              <img src={Eye} width={24} height={24} alt="eye" />
            </Link>
          )}
          <a onClick={() => dispatch(deselectEvent())}>
            <i className="ico ico-x" />
          </a>
        </div>
      </div>
      <SquaredColorOption
        color={getColorCode(event.registrationStatusCode)}
        text={event.registrationStatus}
      />
      <div className={styles.event_subtitle}>
        <i className="ico ico-clock"></i>
        <span>{intervalTimeString}</span>
      </div>
      <div className={styles.event_subtitle}>
        <i className="ico ico-ticket"></i>
        <span>
          {event.spotsBooked}/{event.capacity} {t("Spots Booked")}{" "}
        </span>
      </div>
    </>
  );
};

export default ProgramInfo;
