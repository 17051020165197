import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { isMultiple, newSpaceValues } from "app/actions/spaceCreation";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";
import UserSelect from "app/components/Filter/UserSelect";
import Button from "app/components/Button/ButtonVariant";
import { useCreateNewSpaceModel } from "../../../CreateNewSpace/index.model";
import CustomSelect from "app/components/inputs/CustomSelector";
import { useStoreActions } from "app/utils/hooks";
import { fetchSports } from "app/actions/sports";
import { DefaultOption } from "app/components/inputs/SelectBox/Base";
import { cls } from "app/utils";
import CsvFileUpload from "app/components/CsvFileUpload";
import { TemplateSelect } from "app/components/inputs/SelectBox";
import { useSpaceCsvUploadParser } from "app/utils/hooks/useSpaceCsvUploadParser";

const InvitationToMultipleSpaces = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { spaces_import } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual,
  );
  const { sports } = useStoreActions({ sports: fetchSports }, { noAuth: true });

  const { stepForward, cancelHandler } = useCreateNewSpaceModel();
  const [error, setError] = useState<{
    sport?: boolean;
    athlete?: boolean;
  } | null>({ sport: false, athlete: false });

  const onAthleteSelect = (selectedValue: UserModel[]) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        athletes: selectedValue,
      }),
    );
  };

  const onMemberSelect = (selectedValue: UserModel[]) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        coaches: selectedValue,
      }),
    );
  };

  const handleStepForward = () => {
    if (!spaces_import.athletes || spaces_import.athletes.length === 0) {
      setError({ ...error, athlete: true });
    } else if (!spaces_import.sport || !spaces_import.sport.length) {
      setError({ ...error, sport: true });
    } else {
      setError({ athlete: false, sport: false });
      stepForward();
    }
  };

  const sportSelectHandler = (data: { value: string }) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        sport: data ? data.value : "",
        sport_other: data ? data.value : "",
      }),
    );
  };

  const templateSelectHandler = (value: DefaultOption) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        space_template_id: {
          label: value ? value.label : "",
          id: value ? value.id : "",
        },
      }),
    );
  };

  const [isAthletesModalOpen, setIsAthletesModalOpen] = useState(false);
  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);

  const athletesModalHandler = () => {
    setIsAthletesModalOpen((prev) => !prev);
  };

  const membersModalHandler = () => {
    setIsMembersModalOpen((prev) => !prev);
  };

  // NOTE:
  // csv upload access = /spaces/new/multiple/true?adminCSV=true

  const [isAdminCsv, setIsAdminCsv] = useState(false);

  useEffect(() => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        sport: self?.sport,
        sport_other: self?.sport,
      }),
    );
  }, [self]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const multipleParams = window.location.pathname.includes("new/multiple");
    dispatch(isMultiple(multipleParams));
    const adminCSVParam = params.get("adminCSV");
    setIsAdminCsv(adminCSVParam === "true");
  }, [window]);

  const [uploadedMemberCsv, setUploadedMemberCsv] = useState(null);
  const [uploadedAthletesCsv, setUploadedAthletesCsv] = useState(null);
  const { data: athletesCsv } = useSpaceCsvUploadParser({
    file: uploadedAthletesCsv,
  });
  const { data: membersCsv } = useSpaceCsvUploadParser({
    file: uploadedMemberCsv,
  });

  const filterUsersByEmails = (users: UserModel[]) => {
    return users.filter((user: UserModel) => user.email);
  };

  useMemo(() => {
    if (membersCsv) {
      dispatch(
        newSpaceValues({
          ...spaces_import,
          coaches: [
            ...spaces_import.coaches,
            ...filterUsersByEmails(membersCsv),
          ],
        }),
      );
    }
  }, [membersCsv]);

  useMemo(() => {
    if (athletesCsv) {
      dispatch(
        newSpaceValues({
          ...spaces_import,
          athletes: [
            ...spaces_import.athletes,
            ...filterUsersByEmails(athletesCsv),
          ],
        }),
      );
    }
  }, [athletesCsv]);

  const onAthletesCsvSelect = (data: File) => {
    setUploadedAthletesCsv(data);
    athletesModalHandler();
  };

  const onMembersCsvSelect = (data: File) => {
    setUploadedMemberCsv(data);
    membersModalHandler();
  };

  return (
    <div className={styles.container}>
      <div className={styles.select_input_container}>
        <div className={styles.selectbox_container}>
          <label className={styles.title} htmlFor="space_template">
            {t("What Will These Spaces Be For?")}
          </label>
          <CustomSelect
            placeholder={t("Select Sport / Subject")}
            isCratable
            options={sports?.data}
            value={{
              label: spaces_import?.sport,
              value: spaces_import?.sport,
            }}
            isClearable
            isSearchable
            onChange={(data: { value: string; label: string }) =>
              sportSelectHandler(data)
            }
          />
          {error.sport && (
            <div className="helper-text error">{t("A sport is required.")}</div>
          )}
        </div>
        {self && self.hasCoachPlusSubscription && (
          <TemplateSelect
            isClearable
            isSearchable
            defaultValue={
              spaces_import?.space_template_id?.id?.length && {
                label: spaces_import?.space_template_id.label,
                value: spaces_import?.space_template_id.id,
              }
            }
            onChange={templateSelectHandler}
            className={styles.selectbox_container}
          />
        )}
        <div className={styles.selectbox_container}>
          <label className={styles.title} htmlFor="space_template">
            {t("Who Should Spaces Be Created For?")}
          </label>
          <UserSelect
            value={spaces_import.athletes}
            isCreatable
            noOptionsMessage={t("Add a New Athlete")}
            onChange={onAthleteSelect}
            addNewUserModalTitle={t("Add a New Athlete")}
          />
          {error && error.athlete && (
            <div className="helper-text error">
              {t("An athlete is required.")}
            </div>
          )}
        </div>
        {isAdminCsv && (
          <div
            className={cls(
              styles.selectbox_container,
              styles.csv_add_container,
            )}
          >
            <Button
              onClickFunc={athletesModalHandler}
              title={t("Add from CSV")}
              buttonType="secondary-outline"
              buttonStyles={styles.add_from_csv_btn}
            />
            <span className={styles.upload_container}>
              <CsvFileUpload
                isOpen={isAthletesModalOpen}
                onClose={athletesModalHandler}
                getFileOnUpload={onAthletesCsvSelect}
              />
              <label className={cls("text-muted")}>
                {t(
                  "First two columns in the file must be name and email in that order.",
                )}
              </label>
            </span>
          </div>
        )}
        <div className={styles.selectbox_container}>
          <label className={styles.title} htmlFor="space_template">
            <strong>{t("Invite Coaches or Parents (Optional)")}</strong>
          </label>
          <UserSelect
            value={spaces_import.coaches}
            isCreatable
            noOptionsMessage={t("Add a New Member")}
            onChange={onMemberSelect}
            addNewUserModalTitle={t("Add a New Member")}
          />
        </div>
        {isAdminCsv && (
          <div
            className={cls(
              styles.selectbox_container,
              styles.csv_add_container,
            )}
          >
            <Button
              onClickFunc={membersModalHandler}
              title={t("Add from CSV")}
              buttonType="secondary-outline"
              buttonStyles={styles.add_from_csv_btn}
            />
            <span className={styles.upload_container}>
              <CsvFileUpload
                onClose={membersModalHandler}
                isOpen={isMembersModalOpen}
                getFileOnUpload={onMembersCsvSelect}
              />
              <label className={cls("text-muted")}>
                {t(
                  "First two columns in the file must be name and email in that order.",
                )}
              </label>
            </span>
          </div>
        )}
      </div>
      <div className={styles.button_container}>
        <Button
          title={t("Cancel")}
          buttonType="secondary-outline"
          onClickFunc={cancelHandler}
        />
        <Button
          title={t("Next")}
          buttonType="primary"
          onClickFunc={handleStepForward}
        />
      </div>
    </div>
  );
};

export default InvitationToMultipleSpaces;
