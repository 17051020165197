import React, { useContext  } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { createEvent, editEvent } from "app/actions/events";
import { UserContext, RoleType } from "app/contexts/UserContext";

import styles from "./styles.module.scss";

type PropsDef = {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  status: "success" | "error" | "conflict";
  onDismiss?: () => void;
  onConfirm?: () => void;
  payload?: any;
  action?: "create" | "edit";
  ownEvents?: boolean;
};

export default function InfoModal(props: PropsDef) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useContext(UserContext);

  const {
    isOpen,
    title,
    children,
    status,
    onDismiss,
    payload,
    action,
    ownEvents = false
  } = props;

  if (!isOpen) {
    return null;
  }

  const icon =
    status === "success"
      ? `ico ico-check ${styles.success}`
      : `ico ico-x ${styles.error}`;

  const onConfirmConflict = () => {
    const actionFn = action === "create" ? createEvent : editEvent;

    onDismiss();
    dispatch(actionFn({ ...payload, ignore_conflict: true }));
  };

  const closeButton = (
    <div className={styles.close_button} onClick={onDismiss}>
      <i className="ico ico-x" />
    </div>
  );

  const allCoachIds: string[] = payload?.coachProfileIds || [payload?.coachProfileId];
  const isCoach = userRole.role === RoleType.COACH;
  const ownEvent = ownEvents || (isCoach && allCoachIds.includes(userRole.id));
  const allowConfirmOnConflict = userRole.behavesLikeAdmin || ownEvent;

  const showConflictConfirmation = status === 'conflict' && allowConfirmOnConflict;
  const showOk = status !== 'conflict' || !allowConfirmOnConflict;

  let headerTitle = title;

  if(status === 'conflict' && !showConflictConfirmation) {
    headerTitle = t('Scheduling Conflict');
  }

  return (
    <ModalLayout
      isOpen={isOpen}
      classNames={`${styles.modal} info-modal-container`}
    >
      <div className={`${styles.modal_header} ${styles.w_100} info-modal-header`}>
        <div className={styles.w_100}>
          {status === "conflict" && closeButton}
          <i className={`${icon} ${styles.modal_status_icon}`} />
        </div>
        <h2>{headerTitle}</h2>
      </div>
      <div className={`${styles.modal_content} info-modal-content`}>
        {children}
      </div>

      {showOk && (
        <button
          onClick={onDismiss}
          className={`${styles.confirm_button} btn btn-primary`}
        >
          {t("Ok")}
        </button>
      )}

      {showConflictConfirmation && (
        <div className={styles.confirm_conflict_actions}>
          <button
            onClick={onConfirmConflict}
            className={`${styles.confirm_button} btn btn-primary`}
          >
            {t("Confirm")}
          </button>
          <button
            onClick={onDismiss}
            className={`${styles.cancel_button} btn confirm_cancel_conflict`}
          >
            {t("Cancel")}
          </button>
        </div>
      )}
    </ModalLayout>
  );
}
