import { RootState } from "typedefs";
import { useEffect } from "react";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchEvents } from "../../actions/events";
import { DateTime } from "luxon";

export const useEventsModel = (coachProfileId: string, startDate: DateTime, endDate: DateTime) => {
  const eventActions = useActions({
    fetchEvents,
  });

  const start: string = startDate ? startDate.toISODate() : null;
  const end: string = endDate ? endDate.toISODate() : null;

  useEffect(() => {
    if (!start || !end) {
      return;
    }
    eventActions.fetchEvents({ start, end });
  }, [start, end, coachProfileId]);

  const events = useSelector((state: RootState) => state.events);
  const currentUser = useSelector((state: RootState) => state.user);
  const facilityCoaches = useSelector((state: RootState) => state.user?.self?.scheduling?.facility.coachProfiles) || [];

  if (currentUser.pending) {
    return { events, coachProfile: { data: null, pending: true } };
  }

  const currentCoach = facilityCoaches.find(c => c.id === currentUser.self.id);

  const coachProfile = { data: currentCoach, pending: !currentCoach };

  return { events, coachProfile };
};
