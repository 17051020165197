import React from "react";

import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { DateTime } from "luxon";

type PropsDef = {
  event: any;
  timeText: string;
};

export default function Program(props: PropsDef) {
  const { durationInMinutes } = props.event.extendedProps;
  const coachProfile = useSelector((state: RootState) => state.coachProfile);
  const startTimeString = DateTime.fromISO(props.event.start.toISOString())
    .setZone(coachProfile.data?.timeZone)
    .toLocaleString(DateTime.TIME_SIMPLE);
  return (
    <div
      className={`${styles.event_container} ${
        durationInMinutes < 30 ? styles.small_event : ""
      }`}
    >
      <div className={styles.program_bar}></div>

      <div className={styles.lesson_schedule_event}>
        <div className={styles.event_title}>{props.event.title}</div>
        <div className={styles.event_time}>{startTimeString}</div>
      </div>
    </div>
  );
}
