import React from 'react';
import styles from './styles.module.scss';

type PropsDef = {
  selection: any[];
  onRemove: (id: number) => void;
};

export default function SelectionList({ selection, onRemove }: PropsDef) {

  const userList = selection.map((user: any) => {
    return (
      <div key={user.id} className={styles.list_item}>
        <div className={styles.user_avatar}>
          <img className={styles.user_avatar} src={user.profilePicture} />
          <div className={styles.remove_item} onClick={() => onRemove(user.id)}>
              <i className="ico ico-x" />
          </div>
        </div>
        <span>{`${user.firstName} ${user.lastName}`}</span>
      </div>
    );
  });

  return (
    <div className={styles.list_container}>
      {userList}
    </div>
  );
}
