import PostingForm from "app/components/PostingForm";
import React from "react";
import { TeamsPostingFormModel } from "./indexModel";
import { useTranslation } from "react-i18next";
import PostModel from "app/models/PostModel";
import usePlanFeature from "app/utils/services/plan_feature/user";

interface Props {
  onPost: (post: PostModel) => void;
}

const TeamsPostingForm = ({ onPost }: Props) => {
  const { t } = useTranslation();
  const { selectedTeam, user, canUploadMedia, onSubmit } =
    TeamsPostingFormModel({ onPost });

  if (!selectedTeam) {
    return null;
  }

  const { canSchedulePosts } = usePlanFeature(user);

  return (
    <PostingForm
      textAreaPlaceholder={t(`Post to {{selectedTeam}}`, {
        selectedTeam: selectedTeam.name,
      })}
      canUploadMedia={canUploadMedia()}
      canSchedule={canSchedulePosts}
      profilePicture={user?.avatar as string}
      hasLocationSelect={false}
      postButtonLabel={t("Post")}
      postTextIsRequired
      onPost={onSubmit}
      userName={user?.name}
    />
  );
};

export default TeamsPostingForm;
