import React from "react";

import LessonSchedule from "./LessonSchedule";
import ScheduleBlock from "./ScheduleBlock";

import styles from "./styles.module.scss";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Program from "./Program";

type PropsDef = {
  event: any;
  timeText: string;
  timeZone?: string;
};

function getMinutesDifferenceFromTimeText(timeText: string): number | null {
  const [startTime, endTime] = timeText.split(" - ");

  let start = DateTime.fromFormat(startTime.trim(), "h:mm a");
  let end = DateTime.fromFormat(endTime.trim(), "h:mm a");

  if (!start.isValid) {
    start = DateTime.fromFormat(startTime.trim(), "h a");
  }
  if (!end.isValid) {
    end = DateTime.fromFormat(endTime.trim(), "h a");
  }

  if (!start.isValid || !end.isValid) {
    return null;
  }

  const diffInMinutes = end.diff(start, "minutes").minutes;

  return diffInMinutes;
}

function formatTimeRange(start: Date, end: Date, timeZone: string): string {
  const startTime = DateTime.fromJSDate(start, { zone: timeZone }).toFormat(
    "h:mm a"
  );
  const endTime = DateTime.fromJSDate(end, { zone: timeZone }).toFormat(
    "h:mm a"
  );

  return `${startTime} - ${endTime}`;
}

export default function Event(props: PropsDef) {
  const { t } = useTranslation();

  if (props.event.extendedProps.type === "lesson_schedule") {
    return <LessonSchedule {...props} />;
  } else if (props.event.extendedProps.type === "coach_schedule_block") {
    return <ScheduleBlock {...props} />;
  } else if (props.event.extendedProps.type === "program") {
    return <Program {...props} />;
  } else {
    const timeDifference = getMinutesDifferenceFromTimeText(props.timeText);
    const timeRange = formatTimeRange(
      props.event.start,
      props.event.end,
      props.timeZone
    );

    return (
      <div className={styles.eventWithoutTitle}>
        <div className={styles.titleEvent}>{t("(No Title)")}</div>
        {timeDifference && timeDifference > 45 && (
          <div className={styles.timeEvent}>{timeRange}</div>
        )}
      </div>
    );
  }
}
