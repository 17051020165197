import React, { memo } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import proBadge from "app/images/Pro_badge.svg";
import academyBadge from "app/images/Academy_badge.svg";
import analyzeBadge from "app/images/Analyze_badge.svg";
import { useSubscriptionBadgeModel } from "./indexModel";
import { Link } from "react-router-dom";
import {
  hasAcademyPlan,
  hasAnalyzePlan,
  hasExpiredSubscription,
  hasTrialSubscription,
} from "app/utils/subscription";
import { cls } from "app/utils";

const SubscriptionBadge = (): React.ReactElement => {
  const { t } = useTranslation();
  const {
    trialWarningClass,
    hasActiveProSubscription,
    hasActivePlusSubscription,
    // hasOrganization,

    shouldShowTrialText,
    self,
  } = useSubscriptionBadgeModel();

  if (hasActiveProSubscription()) {
    return (
      <div className={styles.subscriptionBadge}>
        CoachNow{" "}
        <img src={proBadge} className={styles.badge} alt="CoachNow Pro" />
      </div>
    );
  }

  if (hasAcademyPlan(self)) {
    return (
      <div className={styles.subscriptionBadge}>
        CoachNow{" "}
        <img
          src={academyBadge}
          className={styles.badge}
          alt="CoachNow acadmey"
        />
      </div>
    );
  }

  if (hasAnalyzePlan(self)) {
    return (
      <div className={styles.subscriptionBadge}>
        CoachNow{" "}
        <img
          src={analyzeBadge}
          className={styles.badge}
          alt="CoachNow analyze"
        />
      </div>
    );
  }

  if (hasActivePlusSubscription()) {
    return (
      <div className={styles.subscriptionBadge}>
        CoachNow <i className="plus-icon" aria-hidden="true" />
      </div>
    );
  }

  if (hasExpiredSubscription(self)) {
    return <span className={styles.subscriptionBadge}>{t("Expired")}</span>;
  }

  if (shouldShowTrialText()) {
    return (
      <div
        className={cls(styles.trialBadge, {
          [styles.trial_warning]: trialWarningClass(),
        })}
      >
        {t("Trial Expired")}
      </div>
    );
  }

  if (hasTrialSubscription(self)) {
    return (
      <div className={styles.trialBadge}>
        <span>
          {self?.plan?.trialDaysLeft}{" "}
          {t("Days left", { days: self?.plan?.trialDaysLeft })}
        </span>{" "}
        <Link to="/upgrade" className={styles.upgrade_btn}>
          {t("Upgrade")}
        </Link>
      </div>
    );
  }

  return null;
};

export default memo(SubscriptionBadge);
