import React from "react";
import styles from "app/routes/Scheduling/Pricing/Modals/AddPricing/styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import CloseIcon from "../../../../public/images/close.svg";
import {useTranslation} from "react-i18next";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Select, {StylesConfig} from "react-select";
import {Country, State} from 'country-state-city';

interface FormValues {
  id: string;
  name: string;
  country: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
}

interface EditFacilityProps {
  isOpen: boolean;
  onClose: () => void;
  facility: {
    id: string;
    name: string;
    country: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    timezone: string;
  };
  onEdit: (facility: FormValues) => void;
}

const EditFacility = ({isOpen, onClose, facility, onEdit}: EditFacilityProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    trigger,
  } = useForm<FormValues>({
    defaultValues: facility,
  });
  const { t } = useTranslation();


  const onSubmit: SubmitHandler<FormValues> = (data) => {
    onEdit(data);
    onClose();
    reset({
      id: "",
      name: "",
      country: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
    });
  };

  const IndicatorSeparator = (): JSX.Element | null => null;

  const countries = Country.getAllCountries().map((country) => {
    return {
      value: country.isoCode,
      label: country.name,
    };
  });

  const statesForSelectedCountry = (countryId: string) => {
    if (!countryId) {
      return [];
    }

    return State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.isoCode,
    }));
  };

  const customStyles: StylesConfig = {
    valueContainer: (base: any) => ({
      ...base,
      height: '44px',
      width: '150px',
      display: 'flex',
      alignItems: 'center',
    }),
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.modal_header}>
        <h2>{t('Edit Facility')}</h2>
        <img
          className={styles.modal_status_icon}
          src={CloseIcon}
          alt="info"
          onClick={onClose}
        />
      </div>
      <div className={styles.modal_content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="name">{t('Facility Name')} *</label>
          <div className={styles.form_row}>
            <input id="name" {...register("name", {required: 'Facility name is required', minLength: 3})} />
            {errors.name && <span>{errors.name.message}</span>}
          </div>

          <label htmlFor="country">{t('Country')} *</label>
          <div style={{marginBottom: '10px'}}>
            <Controller
              control={control}
              name="country"
              render={({field}) => (
                <Select
                  id="country"
                  value={countries.find(option => option.value === field.value)}
                  onChange={(selectedOption) => {
                    field.onChange((selectedOption as { value: string; label: string })?.value || "");
                    facility.country = (selectedOption as { value: string; label: string })?.value || "";
                    trigger("country");
                  }}
                  options={countries}
                  components={{IndicatorSeparator}}
                  styles={customStyles}
                  placeholder={t('Select Country')}
                />
              )}/>
          </div>

          <label htmlFor="street1">{t('Street Address 1')} *</label>
          <div className={styles.form_row}>
            <input id="street1" {...register("street1", {required: 'Street address is required', minLength: 3})} />
            {errors.street1 && <span>{errors.street1.message}</span>}
          </div>

          <label htmlFor="street2">{t('Street Address 2')}</label>
          <div className={styles.form_row}>
            <input id="street2" {...register("street2")} />
          </div>

          <div className={styles.form_row_columns} style={{gap: "16px"}}>
            <label htmlFor="state">{t("State")} *</label>
            <label htmlFor="city">{t('City')} *</label>
          </div>
          <div className={styles.form_row_columns} style={{gap: "16px", marginBottom: '10px'}}>
            <Controller
              control={control}
              name="state"
              render={({field}) => (
                <Select
                  id="state"
                  value={statesForSelectedCountry(facility.country).find(option => option.value === field.value) || statesForSelectedCountry(facility.country)[0]}
                  onChange={(selectedOption) => {
                    field.onChange((selectedOption as { value: string; label: string })?.value || "");
                    trigger("state");
                  }}
                  options={statesForSelectedCountry(facility.country)}
                  components={{IndicatorSeparator}}
                  styles={customStyles}
                  placeholder={t('Select State')}
                />
              )}/>
            <div>
              <input id="city" {...register("city", {required: 'City is required', minLength: 3})} />
              {errors.city && <span>{errors.city.message}</span>}
            </div>
          </div>

          <label htmlFor="zip">{t("Zip")} *</label>
          <div className={styles.form_row} style={{marginBottom: '10px'}}>
            <input id="zip" {...register("zip", {required: 'Zip code is required', minLength: 3})} />
            {errors.zip && <span>{errors.zip.message}</span>}
          </div>

          <div className={styles.buttonGroup} style={{marginTop: "10px"}}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={onClose}
            >
              {t("Cancel")}
            </button>
            <button type="submit" className={styles.confirmButton}>
              {t("Save")}
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
};

export default EditFacility;
