import React, {useState} from "react";
import styles from "./styles.module.scss";
import {useTranslation} from "react-i18next";
import EditFacility from "app/routes/Facility/EditFacility";

interface FacilityItemProps {
  facility: {
    id: string;
    name: string;
    country: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    timezone: string;
  };
  onEdit: (facility: any) => void;
}

const FacilityItem = ({ facility, onEdit }: FacilityItemProps) => {
  const { t } = useTranslation();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const handleEdit = () => {
    setEditModalOpen(true);
  };

  const fullAddress = facility?.street1 + " " + facility?.city + " " + facility?.state + " " + facility?.zip + " " + facility?.country;

  return  (
    <>
      <div className={styles.container}>
        <div className={styles.first_row}>
          <div className={styles.title}>{t("Facility")}</div>
          <div className={styles.button} onClick={handleEdit}>{t("Edit")}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>Name</div>
          <div className={styles.value}>{facility.name}</div>
        </div>
        <div className={styles.horizontal_line}/>
        <div className={styles.row}>
          <div className={styles.title}>Address</div>
          <div className={styles.value}>{fullAddress}</div>
        </div>
      </div>
      {editModalOpen && (
        <EditFacility
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        facility={facility}
        onEdit={onEdit}/>
      )}
    </>
  );
};

export default FacilityItem;
