import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import useSidebarDropdownModel from "./indexModel";
import { SidebarProps } from "../index.types";
import { useHistory } from "react-router-dom";
import {
  LOCAL_STORAGE_AUTH_TOKEN_NAME,
  LOCAL_STORAGE_AUTH_TOKEN_NAME_TEST,
} from "../indexModel";
import { useTranslation } from "react-i18next";
// import UpgradeToProPaywall from "app/components/paywalls/UpgradeToPro";
import ExplicitPaywall from "app/components/paywalls/Explicit";
import { removeCookie } from "app/utils/cookie";

const SidebarDropdown = ({ user }: SidebarProps) => {
  const { t } = useTranslation();
  const {
    listData,
    modalHandler,
    dropdownRef,
    isOpen,
    onPaywallClose,
    showModal,
  } = useSidebarDropdownModel({ user });

  const history = useHistory();

  const onLogOut = () => {
    if (user.golfgeniusId) {
      window.location.replace(
        "https://www.golfgenius.com/logout?post_sign_out_redirect_url=https://app.coachnow.io/signin"
      );
    } else {
      history.push("/signin");
    }

    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_NAME);
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_NAME_TEST);
    removeCookie("locale");
  };

  return (
    <div className={cls(styles.container)}>
      <div className={styles.wrapper} onClick={modalHandler}>
        <div
          className={cls("bullets", styles.dropdown_btn)}
          onClick={modalHandler}
        />
      </div>
      {isOpen && (
        <div ref={dropdownRef} className={styles.dropdown_container}>
          {listData
            .filter((item) => item.display)
            .map((item) => {
              return (
                <div
                  key={item.title}
                  onClick={item.onClickFunc}
                  className={cls(styles.list, {
                    [styles.bold]: item.isBold,
                  })}
                >
                  {item.title}
                </div>
              );
            })}

          <div
            className={cls(styles.list, styles.bold, "text-danger")}
            onClick={onLogOut}
          >
            {t("Sign Out")}
          </div>
        </div>
      )}
      <ExplicitPaywall show={showModal} onClose={onPaywallClose} />
    </div>
  );
};

export default SidebarDropdown;
