import React from "react";
import styles from "./styles.module.scss";
import playIcon from "../../../public/images/ico_play.svg";
import pauseIcon from "../../../public/images/ico_pause.svg";
import { AudioPlayerProps } from "./index.types";
import useAudioPlayerModel from "./indexModel";
import { cls } from "app/utils";

const AudioPlayer = ({ data }: AudioPlayerProps) => {
  const {
    waveformRef,
    handlePlayPause,
    isPlaying,
    remainingDuration,
    currentId,
    isLoaded,
  } = useAudioPlayerModel({ data });

  return (
    <div className={cls(styles.container)}>
      <div className={cls(styles.audio_attachment_container)}>
        <div onClick={handlePlayPause} className={cls(styles.audio_control)}>
          <img src={isPlaying ? pauseIcon : playIcon} alt="play/pause btn" />
        </div>
        <div
          ref={waveformRef}
          id={`waveform-${currentId}`}
          className={cls({ ["isNotReady"]: !isLoaded })}
        ></div>
        <div className={styles.audio_timer}>{remainingDuration}</div>
      </div>
    </div>
  );
};

export default AudioPlayer;
