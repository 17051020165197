import { useEffect, useRef, useState } from "react";
import WaveSurfer from "../../../vendor/wavesurfer";
import { AudioPlayerProps } from "./index.types";
import moment from "moment";
import { uuid } from "app/utils/uuid";

const useAudioPlayerModel = ({ data }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [remaining, setRemaining] = useState(data.duration || 0);
  const [isLoaded, setIsLoaded] = useState(false);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const waveformRef = useRef(null); // Reference to the waveform container
  const [currentId] = useState(uuid());
  const audioDuration = moment.utc(data.duration).format("mm:ss");

  useEffect(() => {
    if (waveformRef?.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef?.current,
        waveColor: "#dddddd",
        progressColor: "#e54500",
        barWidth: 1.6,
        barGap: 3,
        loopSelection: false,
        barHeight: 4,
        cursorWidth: 0,
        height: 32,
        responsive: true,
      });

      wavesurferRef.current.on("audioprocess", () => {
        if (isPlaying && wavesurferRef.current) {
          const currentTime = wavesurferRef.current.getCurrentTime();
          const duration = wavesurferRef.current.getDuration();
          setRemaining(duration - currentTime);
        }
      });

      wavesurferRef.current.on("ready", () => {
        setRemaining(wavesurferRef.current.getDuration());
        setIsLoaded(true);
      });

      wavesurferRef.current.on("finish", () => {
        setIsPlaying(false);
      });
    }

    return () => {
      wavesurferRef?.current?.destroy();
    };
  }, [currentId]);

  const handlePlayPause = () => {
    if (!wavesurferRef?.current) {
      return;
    }

    if (!isLoaded) {
      wavesurferRef.current.load(data.url);
      setIsLoaded(true);

      wavesurferRef.current.on("ready", () => {
        wavesurferRef.current?.play();
        setIsPlaying(true);
      });
    } else {
      setIsPlaying((prevIsPlaying) => {
        const shouldPlay = !prevIsPlaying;
        if (shouldPlay) {
          wavesurferRef.current?.play();
        } else {
          wavesurferRef.current?.pause();
        }
        return shouldPlay;
      });
    }
  };

  const remainingDuration = isLoaded
    ? moment.utc(Math.max(remaining, 0) * 1000).format("mm:ss")
    : audioDuration;

  return {
    handlePlayPause,
    isPlaying,
    audioDuration,
    remainingDuration,
    currentId,
    isLoaded,
    waveformRef,
  };
};

export default useAudioPlayerModel;
