import { useEffect, useState } from "react";

const useClassDetection = (targetClass: string): boolean => {
  const [hasClass, setHasClass] = useState(false);

  useEffect((): (() => void) => {
    const checkClassChange = (): void => {
      const htmlElement = document.documentElement; // <html> element
      if (htmlElement.classList.contains(targetClass)) {
        setHasClass(true);
      } else {
        setHasClass(false);
      }
    };

    const observer = new MutationObserver(checkClassChange);

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    checkClassChange();

    return (): void => observer.disconnect();
  }, [targetClass]);

  return hasClass;
};

export default useClassDetection;
