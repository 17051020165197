import React, { useMemo } from "react";
import cls from "classnames";
import styles from "./styles.module.scss";
import { v4 as uuid } from "uuid";
import useDatafileIconsSvg from "app/components/inputs/SelectBox/helpers/DatafileIconsSvg";

const DataFilePostItem = ({ mediaData, shouldShowMore, showMoreOverlay }) => {
  const { dataFileIconHandler } = useDatafileIconsSvg();

  const iconClass = useMemo(() => {
    return mediaData?.extension ? styles[mediaData.extension] : "";
  }, [mediaData?.extension]);

  const handleClick = () => {
    if (!shouldShowMore && mediaData?.url) {
      window.open(mediaData.url, "_blank");
    }
  };

  return (
    <div
      key={uuid()}
      className={cls(styles.data_file_container, iconClass)}
      onClick={handleClick}
      data-extension={mediaData?.extension}
    >
        <object
        type="image/svg+xml"
        data={dataFileIconHandler(mediaData?.extension)}
        className={styles.extension_icon}
      />
      <span>{mediaData?.filename ?? ""}</span>
      {shouldShowMore && showMoreOverlay}

    </div>
  );
};

export default DataFilePostItem;
