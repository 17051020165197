export const SelectStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    height: 48,
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
  }),
  valueContainer: (baseStyles) => {
    return {
      ...baseStyles,
      height: 46,
      color: '#000',
      overflow: 'visible',
    }
  },
  input: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    margin: 0,
  }),
};

export const icalUrlRegex =
  /^(webcal|https?):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}([a-zA-Z0-9\-_~:\/?#\[\]@!$&'()*+,;=%\.]*)?$/;


export const MultiSelectStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: '#039BE5',
    borderRadius: '8px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.5rem',
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    color: '#fff',
    fontSize: '14px',
    textAlign: 'center',
    padding: '0 0.5rem',
  }),
  multiValueRemove: () => ({
    color: '#fff',
    fontSize: '18px',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    input: {
      flex: 1,
    }
  }),
};
