import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import styles from "./styles.module.scss";
import {cls} from "app/utils";
import DeleteMenu from "../../../../public/images/delete-icon.svg";
import {useTranslation} from "react-i18next";
import logo_orange_png from 'app/images/logo-orange.png';
import RemoveFacilityUser from "app/routes/FacilityUsers/RemoveFacilityUser";
import EditFacilityUser from "app/routes/FacilityUsers/EditFacilityUser";

interface UserItemProps {
  user: {
    avatar: string;
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    isAdmin: boolean;
  };
  onRemove: (user: any) => void;
  onEdit: (user: any) => void;
}

const UserItem = ({ user, onRemove, onEdit }: UserItemProps) => {
  const { t } = useTranslation();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [menuVisible, setMenuVisible] = useState<string | null>(null);
  const actionMenuRef = useRef<HTMLDivElement>(null);

  const handleArrowClick = (index: string) => {
    setMenuVisible(menuVisible === index ? null : index);
  };

  const handleEditUser = (user: any) => {
    onEdit(user);
    setEditModalOpen(false);
  };

  const handleConfirmRemove = () => {
    onRemove(user);
    setRemoveModalOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target as Node)
    ) {
      setMenuVisible(null);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      handleClickOutside as unknown as EventListener
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside as unknown as EventListener
      );
    };
  }, []);

  const fullName = user.firstName + " " + user.lastName;
  const role = user.role === "coach" && user.isAdmin ? "coach - admin" : user.role;

  return (
    <div className={styles.container}>
      <img className={styles.profile_image} src={user.avatar || logo_orange_png} alt={"profile_image"}/>
      <div className={styles.user_info}>
        <div className={styles.name}>{fullName}</div>
        <div className={`${styles.text} ${styles.email}`}>{user.email}</div>
        <div className={`${styles.text} ${styles.role}`}>{role}</div>
      </div>
      <div className={`${styles.cell} ${styles.iconContainer}`}>
        <div className={cls("bullets", styles.cursorPointer)} onClick={() => handleArrowClick(user.id)}/>
        {menuVisible === user.id && (
          <div ref={actionMenuRef} className={styles.actionMenu}>
            <button
              onClick={() => setRemoveModalOpen(true)}
              className={styles.removeButton}
            >
              <img src={DeleteMenu} alt="deleteMenu" className={styles.deleteImage}/>
              <div style={{marginLeft: "10px"}}>{t("Delete")}</div>
            </button>
          </div>
        )}
      </div>
      <RemoveFacilityUser
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        onDelete={handleConfirmRemove}
        userName={fullName}
      />
      <EditFacilityUser
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        user={user}
        onEdit={handleEditUser}
      />
    </div>
  );
};

export default UserItem;
