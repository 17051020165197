import React, { useState, PropsWithChildren } from 'react';
import { cls } from "app/utils";
import { UserModel } from "app/models";

import { MenuItemData } from ".";

import styles from "./styles.module.scss";

type PropsDef = PropsWithChildren<{
  data: MenuItemData,
  user: UserModel
  onClick: (route: string, targetBlank: boolean) => void;
  isActive?: boolean;
  activeChildren?: boolean;
}>;

export default function Item(props: PropsDef) {
  const { data, user, onClick, isActive, children, activeChildren } = props;

  const [expanded, setExpanded] = useState<boolean>(isActive);

  if (data.displayIf && !data.displayIf(user, data)) {
    return null;
  }

  const handleClick = () => {
    if (activeChildren) {
      setExpanded(!expanded);
    } else {
      onClick(data.route, data.targetBlank);
    }
  };

  return (
    <>
      <div className={styles.sidebar_nav_item} onClick={handleClick}>
        <div className={cls(styles.sidebar_nav_item_wrapper, { [styles.active]: isActive })}>
          {data.icon && <i className={cls("ico", data.icon, styles.icon)} />}
          {data.title}
        </div>
      </div>
      {expanded && activeChildren && children}
    </>
  );
}
