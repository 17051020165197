import React from "react";
import styles from "./styles.module.scss";
import PostingForm from "app/components/PostingForm";
import { usePostToSegmentModel } from "./indexModel";
import { cls } from "app/utils";

const PostToSegment = () => {
  const {
    t,
    canSchedulePosts,
    headerTitle,
    canUploadMedia,
    onSubmit,
    onCancel,
  } = usePostToSegmentModel();

  return (
    <div className={styles.container}>
      <h1 className={cls("panel panel-title", styles.panelTitle)}>
        {t("Create Post for")} &apos;{headerTitle}&apos; {t("List")}
      </h1>
      <PostingForm
        expanded
        textAreaPlaceholder={t("Create post...")}
        canUploadMedia={canUploadMedia()}
        canSchedule={canSchedulePosts}
        profilePicture={null}
        hasLocationSelect={false}
        postButtonLabel={t("Post")}
        postTextIsRequired
        onPost={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};

export default PostToSegment;
