import { useCallback, useState, useEffect } from "react";
import {
  fetchNotifications,
  clear,
  markAllRead,
  markAllSeen,
  getTotalUnseen,
} from "app/actions/Notification";
import { useActions, useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import { notificationSortDate } from "app/utils/notificationDate";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { shallowEqual } from "react-redux";
import { NotificationsModel } from "app/models";

type GroupNotificationTypes = {
  today: NotificationsModel[];
  thisWeek: NotificationsModel[];
  thisMonth: NotificationsModel[];
  everythingElse: NotificationsModel[];
};

export const useNotificationsModel = () => {
  const notificationAction = useActions({
    fetchNotifications,
    getTotalUnseen,
    markAllRead,
    markAllSeen,
    clear,
  });
  const { totalUnseen } = useSelector(
    (state: RootState) => state.notification,
    shallowEqual,
  );

  // const markAllAsSeen = async () => {
  //   if (seenCount === 0) {
  //     return;
  //   }
  //
  //   await notificationAction.markAllSeen().then(() => {
  //     setGroupedNotifications(defaultGroupNotifications);
  //     // initialLoad();
  //   });
  // };

  // const unread = data?.unreadNotificationsCount;

  // --------------------------------------------------

  const defaultGroupNotifications: GroupNotificationTypes = {
    today: [],
    thisWeek: [],
    thisMonth: [],
    everythingElse: [],
  };

  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [notificationsData, setNotificationsData] = useState<
  //   NotificationsModel[]
  // >([]);

  const [groupedNotifications, setGroupedNotifications] =
    useState<GroupNotificationTypes>(defaultGroupNotifications);

  const [notificationsQuery] = useState({ perPage: 50 });

  // useEffect(() => {
  //   if (totalUnseen && totalUnseen?.notifications > 0) {
  //     notificationAction.markAllSeen();
  //     notificationAction.getTotalUnseen();
  //   }
  // }, [totalUnseen?.notifications]);

  const markAllAsRead = useCallback(async () => {
    try {
      setIsReading(true);
      // setNotificationsData([]);
      notificationAction.clear();
      await notificationAction.markAllRead();
      setHasMore(true);
      setGroupedNotifications(defaultGroupNotifications);
      loadData(1);
    } catch (error) {
      throw new Error(error);
    }
    setIsReading(false);
  }, [notificationAction, totalUnseen?.notifications]);

  const groupNotificationsByDate = useCallback(
    (notifications: NotificationsModel[]) => {
      const grouped: GroupNotificationTypes = {
        today: [],
        thisWeek: [],
        thisMonth: [],
        everythingElse: [],
      };

      notifications.forEach((notification: NotificationsModel) => {
        const label = notificationSortDate(notification.createdAt);
        if (label === "Today") {
          grouped.today.push(notification);
        } else if (label === "This Week") {
          grouped.thisWeek.push(notification);
        } else if (label === "This Month") {
          grouped.thisMonth.push(notification);
        } else {
          grouped.everythingElse.push(notification);
        }
      });

      return grouped;
    },
    [],
  );

  const loadData = useCallback(
    async (fetchPage?: number) => {
      setLoading(true);
      const res: { notifications: NotificationsModel[] } =
        await notificationAction.fetchNotifications({
          ...notificationsQuery,
          page: fetchPage ? fetchPage : currentPage,
        });

      if (res?.notifications?.length > 0) {
        const newGroupedNotifications = groupNotificationsByDate(
          res.notifications,
        );

        if (res.notifications.length < notificationsQuery.perPage) {
          setHasMore(false);
        }

        setCurrentPage(fetchPage ? fetchPage + 1 : currentPage + 1);

        // setNotificationsData((prev) => [...prev, ...res?.notifications]);
        setGroupedNotifications((prev) => ({
          today: [...prev.today, ...newGroupedNotifications.today],
          thisWeek: [...prev.thisWeek, ...newGroupedNotifications.thisWeek],
          thisMonth: [...prev.thisMonth, ...newGroupedNotifications.thisMonth],
          everythingElse: [
            ...prev.everythingElse,
            ...newGroupedNotifications.everythingElse,
          ],
        }));
      }
      setLoading(false);
    },
    [currentPage, notificationsQuery, groupedNotifications],
  );

  const [notificationsContainer] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadData,
    disabled: false,
    rootMargin: "0px 0px 200px 0px",
  });

  useEffect(() => {
    loadData(1);

    return () => {
      setGroupedNotifications(defaultGroupNotifications);
      setHasMore(true);
      setCurrentPage(1);
      // setNotificationsData([]);
    };
  }, []);

  const nullData: boolean = Object?.values(groupedNotifications).every(
    (arr): boolean => Array.isArray(arr) && arr.length === 0,
  );

  return {
    markAllAsRead,
    notificationsContainer,
    groupedNotifications,
    loading,
    totalUnseen,
    isReading,
    nullData,
  };
};
