/**
 * @module Reducers.notification
 *
 */
import { RequestError } from "typedefs";
import { NotificationsModel, TotalUnseenModel } from "app/models";
import { NotificationAction } from "app/actions/Notification";

export interface NotificationState {
  data: NotificationsModel[];
  unreadNotificationsData?: {
    notifications?: NotificationsModel[];
    unreadNotificationsCount?: number;
  };
  paginatedData: any;
  error: RequestError | null;
  pending: boolean;
  seenCount: number;
  totalUnseen?: TotalUnseenModel;
  currentPage?: number;
  hasMore?: boolean;
}

const initState: NotificationState = {
  data: null,
  unreadNotificationsData: {
    notifications: null,
    unreadNotificationsCount: 0,
  },
  paginatedData: null,
  error: null,
  pending: false,
  seenCount: 0,
  totalUnseen: {
    invitations: 0,
    notifications: 0,
    total: 0,
  },
  currentPage: 1,
  hasMore: true,
};

const defaultState: NotificationState = {
  data: null,
  paginatedData: null,
  error: null,
  pending: false,
  seenCount: 0,
  currentPage: 1,
  hasMore: true,
};

export default function notificationReducer(
  state = initState,
  action: NotificationAction,
): NotificationState {
  switch (action.type) {
    case "@notification.fetch.pending":
      return { ...state, pending: true };
    case "@notification.fetch.success":
      return { ...state, pending: false, data: action.payload, error: null };
    case "@notification.fetch.unreadNotifications":
      return {
        ...state,
        pending: false,
        unreadNotificationsData: action.payload,
        error: null,
      };
    case "@notification.fetch.paginatedData":
      return {
        ...state,
        pending: false,
        paginatedData: action.payload,
        error: null,
      };
    case "@notification.fetch.error":
      return { ...state, pending: false, error: action.payload };
    case "@notification.fetch.currentPage":
      return { ...state, currentPage: action.payload };
    case "@notification.fetch.hasMore":
      return { ...state, hasMore: action.payload };
    case "@notification.seenCount":
      return { ...state, seenCount: action.payload };
    case "@notification.totalUnseen":
      return { ...state, totalUnseen: action.payload };
    case "@notification.clear":
      return { ...defaultState };
    default:
      return state;
  }
}
