import playIcon from "../../../../../../../public/images/audio-icon.png";
import React from "react";
import styles from "./styles.module.scss";
import { v4 } from "uuid";

interface Props {
  onClick: () => void;
  shouldShowMore?: boolean;
  showMoreOverlay?: React.ReactElement;
}

const AudioPostItem = ({ onClick, shouldShowMore, showMoreOverlay }: Props) => {
  return (
    <div key={v4()} className={styles.audio_media_container} onClick={onClick}>
      {shouldShowMore && showMoreOverlay}
      <img className={styles.play_icon} src={playIcon} />
    </div>
  );
};

export default AudioPostItem;
