import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { sortBy, maxBy } from "lodash";
import { useHistory } from "react-router-dom";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";

import SaveBar from "app/routes/Sessions/SaveBar";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import SelectLessonModal from "./SelectLessonModal";
import Panel from "./Panel";
import DateAndTime from "./DateAndTime";
import {
  setBookSession,
  setIsComplete,
  resetBookSession,
} from "app/actions/scheduling/bookSession";

import styles from "./styles.module.scss";

const SessionForm = ({
  bookLessonData,
  editMode = false,
  disableFacilitySelection = false,
  disableCoachSelection = false,
  isPublicPage = false,
  isForMember = false,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookLessonState = useSelector((state: RootState) => state.bookSession);

  const pricing =
    bookLessonState.pricingValue ||
    bookLessonData?.pricingValues[bookLessonState?.coach?.id]?.find(
      (pv) => pv.defaultRate
    );

  const storeSelection = {
    facility: bookLessonState.facility,
    coach: bookLessonState.coach,
    duration: pricing,
    pricingValue: pricing,
    date: null,
  };
  const [lessonProperties, setLessonProperties] = useState(storeSelection);
  const [selectLesson, setSelectLesson] = useState(false);

  const { t } = useTranslation();

  if (bookLessonState.date) {
    storeSelection.date = DateTime.fromISO(bookLessonState.date);
  }

  const saveLessonProperty = (property, value) => {
    setLessonProperties((state) => {
      return {
        ...state,
        [property]: value,
      };
    });
  };

  const setFacilityHandle = (facility) => {
    saveLessonProperty("facility", facility);
    const facilityCoaches = sortBy(bookLessonData.coaches[facility.id], [
      "order_number",
    ]);
    setCoachHandle(facilityCoaches[0]);
  };

  const setCoachHandle = (coach) => {
    if (coach.value === "all-coaches") {
      const rate = maxBy(
        Object.values(
          bookLessonData.aggregatedRates[lessonProperties.facility.id]
        ),
        (rate: any) => {
          return rate.coachIds.length;
        }
      );
      setLessonProperties((state) => {
        return {
          ...state,
          duration: rate,
          coach,
        };
      });
      dispatch(setBookSession({ duration: rate, time: null }));
    } else {
      const defaultRate =
        bookLessonData.rates[coach.id].find((dur) => dur.defaultRate) ||
        bookLessonData.rates[coach.id][0];

      const pricingValue =
        bookLessonData.pricingValues[coach.id]
          ?.filter((value) => !value.shouldDestroy)
          ?.find((dur) => dur.defaultRate) ||
        bookLessonData?.pricingValues[coach.id]?.[0];

      setLessonProperties((state) => {
        return {
          ...state,
          duration: defaultRate,
          pricingValue,
          coach,
        };
      });
      dispatch(setBookSession({ time: null }));
    }
  };

  const setDurationHandle = (duration) => {
    saveLessonProperty("duration", duration);
  };

  const viewDateHandle = (date) => {
    dispatch(setBookSession({ date: date.toISO() }));
    saveLessonProperty("date", date);
  };

  const coachesForSelect = sortBy(
    bookLessonData.coaches[lessonProperties.facility.id],
    ["order_number"]
  ).filter((c) => c.facilityId === lessonProperties.facility.id);

  if (!disableCoachSelection && coachesForSelect.length > 1) {
    coachesForSelect.unshift({
      fullName: "All Coaches",
      value: "all-coaches",
      id: "all-coaches",
    });
  }

  const allCoachesSelected =
    (lessonProperties.coach as any).value === "all-coaches";
  let selectedCoaches;
  let availableRates;

  if (allCoachesSelected) {
    selectedCoaches = bookLessonData.coaches[lessonProperties.facility.id];
    availableRates = Object.values(
      bookLessonData.aggregatedRates[lessonProperties.facility.id]
    );
  } else {
    selectedCoaches = [lessonProperties.coach];
    availableRates = bookLessonData.rates[lessonProperties.coach.id];
  }

  const pricingValuesForSelect = bookLessonData?.pricingValues[
    lessonProperties?.coach?.id
  ]?.filter((item) => !item.should_destroy);

  const fieldsLists = {
    facilitiesList: bookLessonData.facilities,
    coachesList: coachesForSelect,
    ratesList: availableRates,
    pricingValuesList: pricingValuesForSelect,
  };

  const fieldsEnabled = {
    facilities:
      !disableFacilitySelection && bookLessonData.facilities.length > 1,
    coaches: !disableCoachSelection,
    rates: true,
    pricingValues: pricingValuesForSelect && pricingValuesForSelect.length > 0,
  };

  const fieldsHandle = {
    setFacilityHandle,
    setCoachHandle,
    setDurationHandle,
    setPricingValuesHandle: (pricingValue) => {
      saveLessonProperty("pricingValue", pricingValue);
      setDurationHandle({
        label: pricingValue.duration,
        value: pricingValue.durationInMinutes,
        durationInMinutes: pricingValue.durationInMinutes,
      });
    },
  };

  const onSubmitAllCoaches = (sessionPricing) => {
    setSelectLesson(false);

    dispatch(
      setBookSession({
        facility: lessonProperties.facility,
        pricingValue: sessionPricing,
      })
    );

    if (isForMember) {
      dispatch(setIsComplete(true));
      if (bookLessonState.selectedSession?.id) {
        history.push("/sessions/edit/confirmation");
      } else {
        history.push("/sessions/new/confirmation");
      }
    } else if (isPublicPage) {
      dispatch(setIsComplete(true));
      history.push("/sessions/new/confirmation");
    } else {
      if (bookLessonState.selectedSession?.id) {
        history.push("/sessions/edit/confirmation");
      } else {
        history.push("/sessions/new/confirmation");
      }
    }
  };

  const onSubmit = () => {
    if (lessonProperties.coach.id === "all-coaches") {
      setSelectLesson(true);
    } else {

        dispatch(
          setBookSession({
            facility: lessonProperties.facility,
            pricingValue: lessonProperties.pricingValue,
          })
        );

        if (isForMember) {
          dispatch(setIsComplete(true));
        } else if (isPublicPage) {
          dispatch(setIsComplete(true));
          history.push("/public/confirmation");
        } else {
          if (bookLessonState.selectedSession?.id) {
            history.push("/sessions/edit/confirmation");
          } else {
            history.push("/sessions/new/confirmation");
          }
        }
    }
  };

  const pricingValuesForModal = bookLessonData.pricingValues[
    bookLessonState.coach.id
  ].filter((pv) => {
    return pv?.durationInMinutes === bookLessonState?.duration;
  });

  return (
    <div className={styles.sessions_form_container}>
      <div className={styles.book_form_container}>
        <Panel
          editMode={editMode}
          fieldsLists={fieldsLists}
          fieldsEnabled={fieldsEnabled}
          fieldsHandle={fieldsHandle}
          lessonProperties={lessonProperties}
          hasPricings={
            fieldsLists.pricingValuesList &&
            fieldsLists.pricingValuesList.length > 0
              ? true
              : false
          }
        />
        <DateAndTime
          key={selectedCoaches
            .filter((c) => !c.skipSlots)
            .map((c) => c.id)
            .join("-")}
          viewDate={lessonProperties.date}
          setViewDate={viewDateHandle}
          coaches={selectedCoaches}
          duration={lessonProperties.duration}
          isPublicPage={isPublicPage}
          hasPricings={
            fieldsLists.pricingValuesList &&
            fieldsLists.pricingValuesList.length > 0
              ? true
              : false
          }
        />

        <ModalLayout
          isOpen={selectLesson}
          classNames={styles.select_lesson_modal}
        >
          <div className={styles.modal_header}>
            <h2>{t("Select Session")}</h2>
            <a onClick={() => setSelectLesson(false)}>
              <i className={`${styles.modal_close} ico ico-x`} />
            </a>
          </div>

          <SelectLessonModal
            onClose={() => setSelectLesson(false)}
            pricingValues={pricingValuesForModal}
            lessonProperties={lessonProperties}
            onSubmit={onSubmitAllCoaches}
          />
        </ModalLayout>
      </div>
      <SaveBar
        onSubmit={onSubmit}
        onCancel={() => {
          dispatch(resetBookSession());
          history.push("/sessions");
        }}
        submitDisabled={
          !bookLessonState.time ||
          bookLessonState.coach.lessonRates.length === 0
        }
        showCancel={isPublicPage ? false : true}
      />
    </div>
  );
};

export default SessionForm;
