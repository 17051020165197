import PostingForm from "app/components/PostingForm";
import React from "react";
import { GroupPostingFormModel } from "./indexModel";
import { useTranslation } from "react-i18next";

interface Props {
  onPost?: (post: any) => void;
}

const GroupPostingForm = ({ onPost }: Props) => {
  const { t } = useTranslation();
  const { user, canSchedulePosts, canUploadMedia, onSubmit } =
    GroupPostingFormModel({
      onPost,
    });

  return (
    <PostingForm
      textAreaPlaceholder={t("Post to general")}
      canSchedule={canSchedulePosts}
      profilePicture={user?.avatar as string}
      userName={user?.displayName}
      canUploadMedia={canUploadMedia()}
      hasLocationSelect={false}
      postButtonLabel={t("Post")}
      postTextIsRequired
      onPost={onSubmit}
    />
  );
};

export default GroupPostingForm;
