import React from 'react';
import { useController } from 'react-hook-form';

import styles from './styles.module.scss';

type PropsDef = {
  control: any;
  name: string;
  allowUnavailable?: boolean;
};

const EventTypeSelector = React.forwardRef((props: PropsDef, _ref) => {
  const { control, name, allowUnavailable } = props;

  const { field } = useController({ name, control });

  return (
      <div className={styles.event_type_picker}>
        <div
          className={`${styles.event_type_option} ${field.value === 'lesson_schedule' ? styles.event_type_option_active : ''}`}
          onClick={() => field.onChange('lesson_schedule')}
        >
          Session
        </div>
      {allowUnavailable && (
        <div
          className={`${styles.event_type_option} ${field.value === 'coach_schedule_block' ? styles.event_type_option_active : ''}`}
          onClick={() => field.onChange('coach_schedule_block')}
        >
          Unavailability
        </div>
      )}
      </div>
  );
});

export default EventTypeSelector;
