import React, { memo } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import Button from "app/components/Button/ButtonVariant";
import NotificationsList from "../../NotificationsList";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNotificationsModel } from "./indexModel";

const Notifications = () => {
  const {
    markAllAsRead,
    groupedNotifications,
    notificationsContainer,
    loading,
    isReading,
    nullData,
  } = useNotificationsModel();
  const { t } = useTranslation();
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const readIcon = (
    <i
      className={cls("ico ico-read", styles.read_all, {
        // [styles.read_all]: unread > 0,
      })}
    />
  );

  return (
    <div className={styles.notification_container}>
      <NotificationsList
        data={groupedNotifications}
        nullData={nullData}
        notificationsContainer={notificationsContainer}
        loading={loading || isReading}
      />
      <div className={styles.footer}>
        <Button
          title={t("View All")}
          buttonType="secondary-outline"
          onClickFunc={() => route("/notifications")}
        />
        <Button
          title=""
          buttonStyles={styles.read_all_btn}
          icon={readIcon}
          disabled={isReading}
          buttonType={"secondary-outline"}
          onClickFunc={markAllAsRead}
        />
      </div>
    </div>
  );
};

export default memo(Notifications);
