import React from 'react';

import styles from './styles.module.scss';

export default function SingleValue(ctx) {
  const selection = ctx.getValue()[0];

  return (
    <div className={`${styles.single_value}`}>
      <div className={styles.single_value_avatar}>
        <img src={selection.profilePicture} alt={selection.fullName} />
      </div>
      <div className="option__label">{selection.fullName}</div>
    </div>
  );
}
