import PostingForm from "app/components/PostingForm";
import React from "react";
import { SchedulePostingFormModel } from "./indexModel";
import { useTranslation } from "react-i18next";

interface Props {
  onPost?: (post: any) => void;
}

const SchedulePostingForm = ({ onPost }: Props) => {
  const { t } = useTranslation();
  const { canUploadMedia, onSubmit, canSchedulePosts } =
    SchedulePostingFormModel({
      onPost,
    });

  return (
    <PostingForm
      textAreaPlaceholder={t("Create post...")}
      canUploadMedia={canUploadMedia()}
      canSchedule={canSchedulePosts}
      scheduleIsRequired
      profilePicture={null}
      postButtonLabel={t("Post")}
      postTextIsRequired
      locationsIsRequired
      onPost={onSubmit}
    />
  );
};

export default SchedulePostingForm;
