/**
 * @module Utils
 *
 */
import { Promise } from "rsvp";
import { buildUrl, QueryDef, getQuerySymbol } from "./url";
import createRequest from "./createRequest";
import responseHandler, { ResponseType } from "./responseHandler";
import { getCacheKey } from "app/utils/auth";
import { digest } from "app/utils/runloop";
import config from "config/environment";

export interface RequestOpts {
  method?: string;
  headers?: Headers;
  body?:
    | Blob
    | BufferSource
    | FormData
    | URLSearchParams
    | ReadableStream<Uint8Array>
    | string;
  mode?: RequestMode;
  credentials?: RequestCredentials;
  cache?: RequestCache;
  redirect?: RequestRedirect;
  referrer?: string | "no-referrer" | "client";
  integrity?: string;
  window?: any;
}

export type RInfo = RequestInfo & OptionsDef;
export type RInit = RequestInit & OptionsDef;

export interface OptionsDef extends RequestOpts {
  version?: number | string;
  debug_key?: any;
  noAuth?: boolean;
  noCache?: boolean;
}

export function fetchGet<T = any>(
  type: string,
  query: QueryDef = {},
  opts: OptionsDef = {},
  signal?: AbortSignal,
): Promise<T | T[]> {
  const url = buildUrl(type, query, opts.version);
  return request(url, "GET", null, opts, signal) as Promise<T | T[]>;
}

export function fetchPost<T = any>(
  type: string,
  data: QueryDef = {},
  opts: OptionsDef = {},
): Promise<T> {
  const url = buildUrl(type, {}, opts.version);
  return request(url, "POST", data, opts) as Promise<T>;
}

export function fetchPut<T = any>(
  type: string,
  data: QueryDef = {},
  opts: OptionsDef = {},
): Promise<T> {
  const url = buildUrl(type, {}, opts.version);
  return request(url, "PUT", data, opts) as Promise<T>;
}

export function fetchDelete(
  type: string,
  data: QueryDef = {},
  opts: OptionsDef = {},
): Promise<null> {
  const url = buildUrl(type, {}, opts.version);
  return request(url, "DELETE", data, opts) as Promise<null>;
}

export function fetchApi(input: RInfo, init?: RInit) {
  let url = input as string;
  let options = Object.assign({}, init);

  if (input && (input as Request).url) {
    url = (input as Request).url;
    options = Object.assign({}, options, input, { url: null });
  }

  const version = options.version || 2;
  if (options.version) {
    delete options.version;
  }

  const method = options.method || "GET";
  if (options.method) {
    delete options.method;
  }

  let data: QueryDef | BodyInit | null = options.body || {};
  if (options.body) {
    delete options.body;
  }

  if (method === "GET") {
    url = buildUrl(url, data as QueryDef, version);
    data = null;
  } else {
    url = buildUrl(url, {}, version);
  }

  // create request opts
  const req = createRequest(method, options, data);

  return new Promise((resolve, reject) => {
    (window as any).fetch(url, req).then(resolve).catch(reject);
  });
}

/**
 * request an api from a url
 *
 * @private
 * @method request
 */
export function request(
  url: string,
  method: string,
  data: QueryDef | null,
  options: OptionsDef,
  signal?: AbortSignal,
): Promise<any> {
  data = data || {};

  if (config.INCLUDE_SERVICE_WORKER) {
    if (method === "GET") {
      if (!options.noCache) {
        url += `${getQuerySymbol(url)}_ca=${getCacheKey()}`;
      }
    }
  }

  const req = createRequest(method, options, data);

  function handleResults(res: ResponseType): any {
    digest();
    if (res.success) {
      return res.data;
    } else {
      return Promise.reject(res);
    }
  }

  const controller = new AbortController();
  const { signal: controllerSignal } = controller;

  const fetchSignal = signal || controllerSignal;

  return new Promise((resolve, reject) => {
    fetch(url, { ...req, signal: fetchSignal })
      .catch(responseHandler)
      .then(responseHandler)
      .then(handleResults)
      .then(resolve)
      .catch(reject);
  });
}

export function abortRequest(controller: AbortController) {
  controller.abort();
}
