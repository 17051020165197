import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import VideoPlayer from "app/components/VideoPlayer";
import AudioPlayer from "app/components/AudioPlayer";

interface PostMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  mediaData: any;
}

const PostMediaModal = ({
  isOpen,
  onClose,
  mediaData,
}: PostMediaModalProps) => {
  const { type, originalUrl, url } = mediaData || {};

  const RenderMedia = () => {
    if (type === "image") {
      return <img className={styles.thumbnail} src={originalUrl} alt="media" />;
    }

    if (type === "video") {
      return (
        <div className={styles.video_container}>
          <VideoPlayer src={mediaData} />
        </div>
      );
    }

    if (type === "audio") {
      return (
        <div className={styles.audio_container}>
          <AudioPlayer data={mediaData} />
        </div>
      );
    }

    return null;
  };

  return (
    <ModalLayout
      // classNames={styles.modal_container}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.modal_navbar}>
        <a href={originalUrl || url} target="_blank" rel="noopener noreferrer">
          <i className="ico ico-open" />
        </a>
        <i className="ico ico-x" onClick={onClose} />
      </div>
      <div className={styles.content}>
        <RenderMedia />
      </div>
    </ModalLayout>
  );
};

export default PostMediaModal;
