import React, { useState, useEffect, memo } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../legacy/app/styles/react-datepicker.scss";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import i18n from "app/i18n";

import en from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";

const locales = {
  en,
  ja,
};

type PropsDef = {
  value?: string;
  onClick?: (value: any) => void;
  onChange: (value: any) => void;
};

/**
 * @class ScheduledAtPicker
 *
 */
const ScheduledAtPicker = (props: PropsDef): React.ReactElement => {
  const { t } = useTranslation();
  const [scheduledAt, setScheduledAt] = useState<Date | null>(null);

  useEffect((): void => {
    if (
      props.value !== null &&
      props.value !== undefined &&
      props.value !== ""
    ) {
      setScheduledAt(new Date(props?.value));
    } else {
      setScheduledAt(null);
    }
  }, [props.value]);

  const handleDateChange = (date: Date | null): void => {
    setScheduledAt(date);
    props.onChange(date);
  };

  const ScheduledAtInput = React.forwardRef(
    (props: any, ref: any): React.ReactElement => {
      if (props.value === "") {
        return (
          <div
            className={cls(
              "btn btn-outline-secondary",
              styles.scheduleAtButton,
            )}
            onClick={props.onClick}
            ref={ref}
          >
            <div>
              <i className={cls("ico ico-time", styles.scheduleIcon)} />
              &nbsp;&nbsp;{t("Schedule")}&nbsp;
            </div>
          </div>
        );
      } else {
        const value = moment(scheduledAt);

        value.locale(i18n.language);

        const date = value.format("MMM D, YYYY");
        const time = value.format("hh:mm a");

        return (
          <div
            className={cls(
              "btn btn-outline-secondary",
              styles.scheduleAtButton,
            )}
            onClick={props.onClick}
            ref={ref}
          >
            <div className={styles.scheduledButtonCaption}>
              <i className={cls("ico ico-time")} />
              &nbsp;&nbsp;
              <Trans
                i18nKey="date_published_at_time"
                values={{ date, time }}
                components={{ strong: <strong /> }}
              />
            </div>
          </div>
        );
      }
    },
  );

  useEffect((): void => {
    const currentLocale = locales[i18n.language] || locales.en;
    registerLocale(i18n.language, currentLocale);
  }, [i18n.language]);

  return (
    <div>
      <DatePicker
        locale={i18n.language}
        placeholderText={t("Schedule")}
        selected={scheduledAt}
        onChange={handleDateChange}
        showTimeSelect
        customInput={<ScheduledAtInput />}
        minDate={moment().toDate()}
      />
    </div>
  );
};

export default memo(ScheduledAtPicker);
