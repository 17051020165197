import { useActions, useSelector } from "app/utils/hooks";
import { RootState } from "typedefs";
import { PostingFormProps } from "./index.types";
import { useCallback, useEffect, useState } from "react";
import {
  clearMediaState,
  fetchMediaById,
} from "app/actions/media-library-data";
import { clearDestinations } from "app/actions/destinations";
import { PostingModel } from "app/models/PostModel";
import TagModel from "app/models/TagModel";
import { toInteger } from "lodash";
import { shallowEqual } from "react-redux";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";

const initPostLocations: {} = {
  smart_list_ids: [],
  space_id: [],
  team_ids: [],
  segment_ids: [],
  team_group_ids: [],
};

const usePostingForm = ({
  post,
  attachmentProps,
  onPost,
  onCancel,
  expanded,
  postTextIsRequired,
  locationsIsRequired,
  scheduleIsRequired,
  scheduledInActive,
}: PostingFormProps) => {
  const postingFormExpandHandler = () => {
    setPostingFormIsExpanded((prev) => !prev);
  };

  // to shrink textarea
  const postingFormShrinkHandler = useCallback(() => {
    setPostingFormIsExpanded(false);
  }, []);

  const resourceActions = useActions({
    fetchMediaById,
    clearMediaState,
    clearDestinations,
  });

  const { pending } = useSelector(
    (state: RootState) => state.mediaLibraryData,
    shallowEqual,
  );

  const selectedLocations = useSelector(
    (state: RootState) => state.destinations.selectedDestinations,
    shallowEqual,
  );

  const [attachment, setAttachment] = useState<MediaTypes | null>(
    post?.resources?.[0] || null,
  );
  const [isProcessed, setIsProcessed] = useState(
    attachment?.processed || false,
  );
  const [postTextError, setPostTextError] = useState<boolean>(false);
  const [postLocationsError, setPostLocationsError] = useState<boolean>(false);
  const [scheduleError, setScheduleError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [postLocations, setPostLocations] = useState(initPostLocations);
  const [postingFormIsExpanded, setPostingFormIsExpanded] = useState<boolean>(
    expanded || false,
  );
  const [pickedSchedule, setPickedSchedule] = useState(null);
  const [newTags, setNewTags] = useState<any>(
    attachment?.tags || post?.tags || [],
  );
  const [newPostData, setNewPostData] = useState<PostingModel | null>(
    post || {
      details: "",
      tags: newTags,
      resource_id: attachment?.id,
      resource_type: attachment?.type,
    },
  );
  const [paywallIsOpened, setPaywallIsOpened] = useState(false);

  const paywallModalHandler = () => {
    setPaywallIsOpened((prev) => !prev);
  };

  useEffect(() => {
    if (attachmentProps) {
      setAttachment(attachmentProps);
      setNewTags(attachmentProps?.tags);
    }
  }, [attachmentProps]);

  const onSelectMedia = (media: MediaTypes) => {
    setAttachment(media);
  };

  const onClearMedia = () => {
    setAttachment(null);
  };

  // useEffect for polling attachment if its not processed
  useEffect(() => {
    setNewPostData({
      ...newPostData,
      resource_id: attachment?.id,
      resource_type: attachment?.type,
    });

    const pollingTimer = setInterval(() => {
      if (attachment && attachment.processed) {
        clearInterval(pollingTimer);
        setIsProcessed(true);
      }
      if (attachment && attachment.type === "video" && !isProcessed) {
        resourceActions.fetchMediaById(attachment.id);
      }
    }, 3000);

    return () => {
      clearInterval(pollingTimer);
    };
  }, [attachment, isProcessed]);

  // check selectedLocations
  useEffect(() => {
    if (selectedLocations?.length > 0) {
      if (errorMessage) {
        setErrorMessage(null);
      }
      selectedLocations?.forEach((obj: { id: string; object: string }) => {
        let idx: string;
        switch (obj.object) {
          case "smart_list":
            idx = "smart_list_ids";
            break;
          case "athlete_space":
            idx = "space_id";
            break;
          case "team":
            idx = "team_ids";
            break;
          case "segment":
            idx = "segment_ids";
            break;
          default:
            idx = "team_group_ids";
            break;
        }

        setPostLocations((prevState: any) => ({
          ...(prevState ?? {}),
          [idx]: [...(prevState[idx] || []), obj.id],
        }));
      });
    }

    return () => {
      setPostLocations(initPostLocations);
    };
  }, [selectedLocations]);

  useEffect(() => {
    setNewPostData({
      ...newPostData,
      ...postLocations,
    });
  }, [postLocations]);

  const textAreaHandler = useCallback((e: string) => {
    setNewPostData((prevData) => ({
      ...prevData,
      details: e,
    }));
  }, []);

  const onTagsChange = (tags: TagModel[]) => {
    if (tags) {
      setNewTags(tags as any);
      const tagNames = tags.map((item: { name: string }) => item.name);
      setNewPostData((prevData: any) => ({
        ...prevData,
        tags: tagNames,
      }));
    }
  };

  const onScheduleChange = useCallback(
    (pickedAt: number, pickedTime?: "Days" | "Hours" | "Weeks") => {
      if (pickedAt) {
        setPickedSchedule(pickedAt);

        if (scheduledInActive) {
          let calculatedTime = toInteger(pickedAt);
          if (pickedTime === "Days") {
            calculatedTime = toInteger(pickedAt) * 24;
          } else if (pickedTime === "Weeks") {
            calculatedTime = toInteger(pickedAt) * 7 * 24;
          } else {
            calculatedTime = toInteger(pickedAt);
          }
          setNewPostData((prevData) => ({
            ...prevData,
            scheduled_in: toInteger(calculatedTime),
          }));
        } else {
          setNewPostData((prevData) => ({
            ...prevData,
            scheduled_at: new Date(pickedAt).toISOString(),
          }));
        }
      }
    },
    [],
  );

  const clearPostingForm = () => {
    resourceActions.clearMediaState();
    resourceActions.clearDestinations();
    postingFormShrinkHandler();
    setErrorMessage(null);
    setPostTextError(false);
    setPostLocationsError(false);
    setScheduleError(false);
    setPostLocations(null);
    setNewPostData(null);
    setPickedSchedule(null);
    setNewTags(null);
    setAttachment(null);
  };

  useEffect(() => {
    // clear everything on unmount
    return () => clearPostingForm();
  }, []);

  const onSubmitFunction = () => {
    if (postTextIsRequired || locationsIsRequired || scheduleIsRequired) {
      // If post text is required
      if (
        !attachment &&
        postTextIsRequired &&
        (!newPostData ||
          !newPostData.details ||
          newPostData.details.trim() === "")
      ) {
        // if post text is required, but not provided
        setPostTextError(true);
        return;
      } else {
        if (postTextError) {
          setPostTextError(false);
        }
      }

      if (
        locationsIsRequired &&
        (!selectedLocations || !selectedLocations.length)
      ) {
        // if location is required, but not selected
        setPostLocationsError(true);
        return;
      } else {
        if (postLocationsError) {
          setPostLocationsError(false);
        }
      }

      if (scheduleIsRequired && !pickedSchedule) {
        // if schedule is required, but not selected
        setScheduleError(true);
        return;
      } else {
        if (scheduleError) {
          setScheduleError(false);
        }
      }
    }

    // reset error states
    setPostTextError(false);
    setPostLocationsError(false);

    // proceed with posting
    Promise.resolve(onPost(newPostData));
    clearPostingForm();
  };

  const onCancelHandler = () => {
    // clear all posting form states
    clearPostingForm();
    // check if cancel is passed
    if (onCancel) {
      onCancel();
    }
  };

  return {
    pending,
    postTextError,
    textAreaHandler,
    onTagsChange,
    onScheduleChange,
    onSelectMedia,
    onClearMedia,
    newPostData,
    postingFormExpandHandler,
    postingFormIsExpanded,
    pickedSchedule,
    onSubmitFunction,
    onCancelHandler,
    newTags,
    postLocationsError,
    scheduleError,
    attachment,
    paywallModalHandler,
    paywallIsOpened,
  };
};

export default usePostingForm;
